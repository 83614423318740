section.cart.step-1 {
    .row_without_productos {
        height: 75vh;
        .without_productos {
            @include LatoGrandeMobile;
            margin-top:30px;
        }
        .link-general {
            margin-top: 30px;
            a {
                @include ColorPrincipal;
                @include LatoMedianoMobile;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .contianer-items-cart {
        margin-top:20px;
        .content-cart {
            background: #FFFFFF;
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 15px;
            padding-right: 15px;
        }
        .container-qty {
            .less-qty {
                &.disabled {
                    .img-less {
                        cursor: initial;
                        rect {
                            fill: #8A8D8E;
                            stroke: #8A8D8E;
                        }
                    }
                }
            }
        }
    }
    .add_discount{
        cursor: pointer;
        @include ColorSecundario;
        @include LatoPequenoDesktop;
    }
    .form_discount {
        display: none;

        .form-control.coupon {
            margin-right: 20px;
            text-align: center;
        }
        .btn-std {
            padding-top: 5px;
            padding-bottom: 6px;
            padding-left: 16px;
            padding-right: 16px;
        }
    }
    .discount_status_container {
        display: none;
        .discount_status {
            padding: 10px;
            max-width: 380px;
            margin: 0 auto;
            &.discount_status_ok {
                color: #28a745;
                border: 2px solid #28a745;
            }
            &.discount_status_ko {
                color: #ff0000;
                border: 2px solid #ff0000;
            }

        }

    }
}

@media (max-width: 1200px) {
    section.cart.step-1{
        .contianer-items-cart {
            .content-cart-product {
                background: #FFFFFF;
                padding-top: 20px;
                padding-bottom: 20px;
                .content-cart {
                    background: none;
                    padding: 0;
                }
            }
            .content-resumen {
                background: #FFFFFF;
                padding-top: 20px;
                padding-bottom: 20px;
                border-top: 1px solid #8A8D8E;
                .block_product {
                    background: none;
                    padding: 0;
                    border-top: none;
                    max-width: 100%;
                }
            }
        }
    }
}

@media (max-width: 991px) {
    section.cart.step-1 .contianer-items-cart {
        margin-bottom: 140px;
    }
}

@media (max-width: 768px) {
    section.cart.step-1 {
        .contianer-items-cart {
            margin-bottom: 162px;
        }
    }
}
