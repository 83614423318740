footer {
    position: relative;
    background-color: #8A8D8E;
    padding-top: 73px;
    padding-bottom: 52px;
    margin-top: 150px;
    .footer-menu {
        .line-item {
            margin-top: 8px;
            margin-bottom: 7px;
            border-top: 1px solid #FFFFFF;
        }
        .list-menu-footer {
            list-style: none;
            padding: 0;
        }
        .title-footer {
            font-family: "Lato-Light";
            font-size: 18px;
            line-height: 20px;
            color: #FFFFFF;
            margin-bottom: 16px;
        }
        .text-footer {
            font-family: "Lato-Light";
            font-size: 18px;
            line-height: 20px;
            color: #FFFFFF;
            margin-bottom: 16px;
        }
        .link-footer {
            margin-bottom: 4px;
            a, p {
                font-family: "Lato-Light";
                font-size: 18px;
                line-height: 20px;
                color: #FFFFFF;
                text-decoration: none;
                &:hover {
                    color: #FFFFFF;
                    text-decoration: underline;
                }
            }
        }
        .content-desplegable {
            display: none;
        }
        .social-networks {
            margin-top: 43px;
            max-width: 160px;
            width: 100%;
            .img-social {
                width: 35px;
                height: 35px;
                path {
                    fill: #FFFFFF;
                }
                &:hover {
                    path {
                        fill: #E03828;
                    }
                }
            }
        }
        .img-card {
            max-width: 274px;
            width: 100%;
            margin-top: 26px;
        }
        &.footer-menu-other-links {
            .link-footer {
                margin-bottom: 17px;
            }
        }
    }

    .legal-text-footer {
        margin-top: 30px;
        .link-white {
            color: #FFFFFF;
            text-decoration: none;
            @include LatoMedianoMobile;
            font-family: "Lato-Regular";
            &:hover {
                color: #FFFFFF;
                text-decoration: underline;
            }
        }
    }
    .logo-iso {
        margin-top: 50px;
    }
    .download-pdf-iso {
        .pdf-iso {
            color: #454040;
            text-decoration: none;
            @include LatoMedianoMobile;
            font-family: "Lato-Regular";
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .footer-logo, .logo-iso {
        img {
            max-width: 213px;
            width: 100%;
        }
    }
    .container-accio{
        margin-top: 30px;
        a.link-accio{
            color: #FFFFFF;
            text-decoration: underline;
            @include LatoMedianoMobile;
            font-family: "Lato-Regular";
    
        }
    }
}

@media (max-width: 768px) {
    footer {
        margin-top: 50px;
        .footer-menu {
            .title-footer {
                font-family: "Lato-Light";
                font-size: 18px;
                line-height: 20px;
                color: #FFFFFF;
                margin-bottom: 16px;
            }
            .link-footer {
                a, p {
                    font-size: 16px;
                    line-height: 20px;
                }
            }
            .text-footer {
                font-size: 16px;
                line-height: 20px;
            }
        }
        .logo-iso {
            margin-top: 13px;
        }
        .footer-logo {
            .img-logo{
                max-width: 200px
            }
            .img-iso{
                max-width: 180px;
            }
            img {
                width: 100%;
            }
        }
        .logo-iso {
            margin-bottom: 60px;
        }
        .content-other-links {
            margin-top: 40px;
        }
        .container-accio{
            .container-projecte{
                order: 2;
            }
            .content-logo{
                order: 1;
                margin-bottom: 20px;
            }
        }
    }
}
