section.historico {
    .row_historico {
        margin-bottom: 50px;
        background-image: url('/images/banner/banner-historico.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        .row_title_historico {
            min-height: 201px;
            align-items: center;
        }
        .title-section {
            @include ColorSecundario;
            @include LatoXlDesktop;
        }
        .subtitle-section {
            @include ColorPrincipal;
            @include LatoGrandeMobile;
        }
    }
    .content-results-aplications {
        .table-std {
            width: 100%;
            border-collapse: separate;
            border-spacing: 11px;
            th {
                @include ColorSecundario;
                @include LatoGrandeMobile;
                border-bottom: 1px solid #E03828;
                padding-bottom: 13px;
            }
            td {
                font-size: 18px;
                font-family: 'Lato-Light';
                line-height: 20px;
                @include ColorPrincipal;
                border-bottom: 1px solid #8A8D8E;
                padding-bottom: 15px;
                padding-top: 10px;
            }
        }
    }
}

@media (max-width: 768px) {
    section.historico {
        .row_historico {
            .title-section {
                font-size: 32px;
                line-height: 38px;
            }
        }
        .content-results-aplications {
            table {
                display: block;
                overflow-x: auto;
                white-space: nowrap;
                .padding-general {
                    padding: 0.5em 1.7em;
                }
                th, td {
                    padding: 0.5em 0.7em;
                }
            }
        }
    }
}

@media (max-width: 390px) {
    section.historico {
        .content-results-aplications {
            table {
                display: block;
                overflow-x: auto;
                white-space: nowrap;
                .padding-general {
                    padding: 0.5em 1.2em;
                }
                th, td {
                    padding: 0.5em 1.5em;
                }
            }
        }
    }
}
