.containerGeneralCarousel {
    margin-bottom: 50px;
    height: 400px;
    position: relative;
    .contentCarousel {
        height: 100%;
        .carouselImages {
            height: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }
    }
    .containerImageColor {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.8);
        max-width: 828px;
        .productDetail{
            height: 100%;
            .rowContentBanner {
                height: 100%;
                .title-banner-home {
                    color: #8A8D8E !important;
                    font-size: 64px;
                    font-family: "Lato-Thin";
                    line-height: 64px;
                }
                .subtitle-banner-home {
                    font-family: 'Lato-Light';
                    font-size: 24px;
                    line-height: 24px;
                    color: #000000 !important;
                }
                .subtitleBanner {
                    @include ColorPrincipal;
                    margin-bottom: 18px;
                }
                .subtitleBannerAplication {
                    color: #000000;
                    @include LatoMedianoDesktop;
                }
                .titleBanner {
                    @include LatoExtraGrande;
                    @include ColorSecundario;
                }
                .titleBannerAplication {
                    @include ColorPrincipal;
                }
            }
        }
    }
}
@media (max-width: 768px) {
    .containerGeneralCarousel {
        .containerImageColor {
            min-height: 196px;
            height: auto;
            .productDetail {
                min-height: 150px;
                .rowContentBanner {
                    min-height: inherit;
                    .title-banner-home {
                        font-family: "Lato-Light";
                        font-size: 42px;
                        line-height: 43px;
                    }
                    .subtitle-banner-home {
                        font-family: 'Lato-Light';
                        font-size: 21px;
                        line-height: 21px;
                    }
                }
            }
        }
    }
}
