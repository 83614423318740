@media (max-width: 768px) {
    section.applications {
        table {
            display: block;
            overflow-x: auto;
            white-space: nowrap;
            .padding-general {
                padding: 0.5em 1.7em;
            }
            th, td {
                padding: 0.5em 0.7em;
            }
        }
    }

}

@media (max-width: 390px) {
    section.applications {
        table {
            display: block;
            overflow-x: auto;
            white-space: nowrap;
            .padding-general {
                padding: 0.5em 0.9em;
            }
            th, td {
                padding: 0.5em 0.4em;
            }
        }
    }
}
