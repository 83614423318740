.content-legal-text {
    margin-top: 30px;
    margin-bottom: 30px;
    .title-general {
        @include LatoExtraGrande;
    }
    .title-principal {
        @include LatoGrande;
        @include ColorPrincipal;
    }
    .content-principal {
        @include LatoMedianoDesktop;
        @include ColorPrincipal;
    }
    .list-principal {
        list-style: none;
    }
}
