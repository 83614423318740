.nav-location-step-in-cart {
    background: #fbfaf8;
    padding-top: 30px;
    .logo-header {
        width: 100%;
        max-height: 92px;
        max-width: 92px;
    }
    .line-logo-step {
        display: none !important;
    }
    .container-steps {
        text-align: center;
        margin-top: 9px;
    }
    .text {
        @include ColorPrincipal;
        @include LatoMedianoDesktop;
        display: inline-block;
        margin-bottom: 0;
    }
    .black {
        @include ColorSecundario;
    }
    .flecha {
        @include ColorPrincipal;
        font-size: 12px;
        display: inline-block;
        width: 14px;
        height: 14px;
        margin-bottom: 5px;
        margin-left: 15px;
        margin-right: 15px;
    }
    .line-location {
        border-top: 1px solid gray;
        height: 1px;
        margin-top:30px;
        margin-bottom: 80px;
    }
}
//Ubicar en lugar correcto, css para el step 1
.text-continue-buy{
    font-size:16px;
}
.text_resumen {
    @include ColorPrincipal;
    @include LatoGrandeMobile;
    margin-bottom: 20px;
}
.container-items-cart {
    position: fixed;
    background: rgba(255, 255, 255);
    z-index: 10;
    width: 100%;
    left: 0;
    bottom: 0;
    .text-general {
        @include ColorPrincipal;
        @include LatoPequenoDesktop;
        line-height: 28px !important;
    }
    .btn-next {
        max-width: 452px;
        width: 100%;
    }
}
.contianer-items-cart {
    .line-container {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .item-carrito {
        .second-content {
            margin-top: 12px;
            .content-qty {
                font-family: "Lato-Light";
                font-size: 18px;
                line-height: 20px;
                @include ColorPrincipal;
            }
        }
        .line-item {
            margin-top: 15px;
            border-top: 1px solid #8A8D8E;
        }
        .line-item-margin {
            margin-bottom: 30px;
        }
        .img-carrito {
            width: 100%;
        }
        .price_product {
            margin-top: auto;
            font-family: "Lato-Light";
            font-size: 18px;
            line-height: 20px;
            @include ColorPrincipal;
        }
        .description-product {
            font-family: "Lato-Light";
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 0;
        }
        .name_product {
            margin-bottom: 0;
            font-family: "Lato-Light";
            font-size: 18px;
            line-height: 20px;
            @include ColorPrincipal;
        }
        .reference_product {
            font-family: "Lato-Light";
            font-size: 18px;
            line-height: 20px;
            @include ColorPrincipal;
        }

        .texto-tachado{
            text-decoration:line-through;
        }
        .container-qty{
            font-family: "Lato-Light";
            font-size: 18px;
            line-height: 20px;
            margin-left: 11px;
            @include ColorPrincipal;
            .plus-qty, .less-qty{
                width: 24px;
                height: 24px;
            }
            .plus-qty {
                margin-left: 11px;
            }
        }
        .delete-trashing {
            margin-top: auto;
            margin-bottom: 5px;
        }
    }
    .delete-trashing:hover{

        svg {
            path {
              fill: black;
            }
          }
    }

}
section.cart.step-1 {
    .block_product {
        border-top: 1px solid #8A8D8E;
        background: #FFFFFF;
        padding-top: 11px;
        padding-bottom: 26px;
        padding-left: 20px;
        padding-right: 20px;
        max-width: 452px;
        width: 100%;
        .total-iva {
            @include LatoMedianoMobile;
            .iva {
                margin-left: 12px;
                font-style: italic;
                @include LatoPequenoMobile;
            }
        }
        .total-initial {
            @include LatoMedianoDesktop;
        }
        .texto-envio {
            @include LatoPequenoDesktop;
            line-height: 24px !important;
            a {
                color: #000000;
                text-decoration: underline;
                &:hover {
                    @include ColorSecundario;
                }
            }
        }
    }
}
section.cart.steps-2, section.cart.step-3 {
    .content-resumen {
        margin-top: 107px;
        .block_product {
            background: #FFFFFF;
            max-width: 452px;
            width: 100%;
            .cart-content-step {
                padding: 30px;
                .description-ref {
                    font-family: "Lato-Light";
                    font-size: 18px;
                    line-height: 22px;
                    margin-bottom: 11px;
                    .ref {
                        @include ColorPrincipal;
                    }
                }
                .qty-box, .price {
                    @include ColorPrincipal;
                    @include LatoMedianoMobile;
                }
            }
            .line-item {
                margin-top: 13px;
                margin-bottom: 20px;
                border-top: 0.5px solid #8A8D8E;
            }
            .line-width {
                margin-top: 21px;
                margin-bottom: 34px;
            }
            .total-iva {
                @include LatoMedianoMobile;
                .iva {
                    margin-left: 12px;
                    font-style: italic;
                    @include LatoPequenoMobile;
                }
            }
            .total-initial {
                @include LatoMedianoDesktop;
            }
            .texto-envio {
                @include LatoPequenoDesktop;
                line-height: 24px !important;
                a {
                    color: #000000;
                    text-decoration: underline;
                    &:hover {
                        @include ColorSecundario;
                    }
                }
            }
            .text_gastos, .price_gastos {
                @include LatoMedianoMobile;
            }

        }
    }

}

section.cart {
    background: #fbfaf8;
    .title_std {
        @include ColorPrincipal;
        @include LatoGrandeMobile;
        margin: 0 auto;
        margin-bottom: 24px;
    }

    .without_stock {
        text-align: center;
        margin-top: 20px;
        margin-bottom: 50px;
        color: red;
    }

    .line-container {
        margin-top: 21px;
        .line-item {
            border-top: 1px solid #8A8D8E;
            height: 1px;
            margin: 0;
            width: 100%;
        }
    }
    .text-grey {
        color: #d6d0c4;
    }

    .column-icons {
        text-align: center;
    }
    .btn-std{
        max-width: 270px;
    }

    &.cart-ok{
        padding-top: 50px;
        padding-bottom: 50px;
        .text-content {
            @include LatoMedianoMobile;
            color: #000000;
        }
        .content-ko-ok-general {
            height: calc(100vh - 100px);
        }
        .subtitle-status {
            max-width: 604px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 30px;
            margin-bottom: 20px;
            color: #282828;
            @include LatoMedianoMobile;
        }
        .link-status {
            a {
                @include ColorPrincipal;
                @include LatoMedianoDesktop;
                &:hover {
                    text-decoration: none;
                    @include ColorSecundario;
                }
            }
        }
        .row-link {
            .link-status-small {
                @include ColorPrincipal;
                @include LatoPequenoDesktop;
                &:hover {
                    text-decoration: none;
                    @include ColorSecundario;
                }
            }
            margin-bottom: 50px;
        }

        .text-ok,.text-ko{
            background-color: #f5f2ea;
            margin-bottom: 30px;
            margin-top: 25px;
            padding-top: 15px;
            padding-bottom: 15px;
        }
        .text-content {
            margin-top: 30px;
            .text-status {
                @include LatoMedianoMobile;
            }
            .email {
                @include ColorPrincipal;
            }
        }
        .text-ok {
            .text-title {
                @include LatoGrandeMobile;
                color: #66BC29;
            }
        }
        .text-ko{
            .text-title{
                @include ColorSecundario;
                @include LatoGrandeMobile;
            }
        }
        .title-ok{
            @include LatoExtraGrande;
            color: #282828;
        }
        .text-title{
            color: #28a745;
        }
        .text-content{
            .content-ok-email{
                color: #a4a097;
            }
        }
        .row-networks{
            margin-top: 30px;
        }
        .title-networks{
            margin-top: 20px;
        }
        .social-media-icons {
            margin-right: 55px;
            &:hover path{
                fill: #00bde0;
            }
        }
    }
}

@media (max-width: 991px) {
    section.cart.step-3 .container-payments .title_std, section.cart.step-3 .container-payments .payment-types{
        position: initial;
    }
    section.cart .container-actions .container-next{
        margin-bottom: 33px;
    }
    section.cart.step-3 .container-payments .error-form{
        position: initial;
        text-align: center;
    }
    section.cart.step-3 {
        .block-product {
            max-width: 100%;
        }
    }
    .container-items-cart {
        .block_product {
            max-width: 100%;
        }
    }
    section.cart.steps-2, section.cart.step-3 {
        .content-resumen {
            background-color: #FFFFFF;
            padding-top: 30px;
            padding-bottom: 30px;
            margin-top: auto;
            .block_product {
                background: none;
                max-width: 100%;
                .cart-content-step {
                    background: none;
                    padding: 0;
                    max-width: 100%;
                }
            }
        }
    }
}
@media (max-width: 768px) {
    .nav-location-step-in-cart {
        margin-top: 12px;
        .container-steps {
            margin-top: 41px;
        }
        .text {
            @include LatoMedianoMobile;
        }
    }
    .container-items-cart {
        .text-general {
            @include LatoPequenoMobile;
            line-height: 14px !important;
        }
    }
}
@media (max-width: 414px) {
    .contianer-items-cart .item-carrito .container-qty .plus-qty, .contianer-items-cart .item-carrito .container-qty .less-qty{
        width: 20px;
        height: 20px;
        margin-right: 5px;
        margin-left: 5px;
    }
    .contianer-items-cart .item-carrito .container-qty{
        margin-left: 0px;
    }
    section.cart.step-3 .item-container{
        height: 55px;
    }
    section.cart.step-3 .container-payments .payment-types{
        min-width: 298px;
    }

    section.cart.cart-ok .title-ok{
        font-size: 24px;
    }
}


