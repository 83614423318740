section.productEstructure {
    overflow: hidden;
    .columnTitleProduct {
        p {
            font-family: "Lato-Regular";
            font-size: 20px;
            line-height: 24px;
            @include ColorPrincipal;
            span {
                @include LatoMedianoDesktop;
                line-height: 24px;
            }
        }
    }
    .column-content {
        margin-bottom: 0px;
    }
    .columnCategories {
        background-color: rgba(138, 141, 142, 0.1);
        padding-top: 20px;
        padding-bottom: 30px;
        padding-left: 30px;
        position: absolute;
        left: 0;
        right: -116px;
        z-index: 99;
        .listCategories {
            max-width: 600px;
            width: 100%;
            .textCategory {
                span {
                    max-width: 90%;
                }
            }
        }
    }
    .box-relative {
        .content-description {
            @include LatoMedianoDesktop;
        }
        p {
            @include LatoMedianoDesktop;
            @include ColorSecundario;
        }
        .btnDownloadCatalogue {
            padding: 10px;
            max-width: 278px;
            width: 100%;
            font-family: "Lato-Regular";
            font-size: 20px;
            line-height: 24px;
        }
        .btnSearchAplication {
            padding: 10px;
            max-width: 278px;
            width: 100%;
            font-family: "Lato-Regular";
            font-size: 20px;
            line-height: 24px;
        }
    }
    .spacer {
        height: 50px;
        margin-top: 0 0 -50px 0;
        background: transparent;
    }
    .shop-under-request {
        margin-bottom: 35px;
        .btn-under-request {
            vertical-align: bottom;
        }
        .text-under-request {
            color: #8A8D8E;
            font-family: 'Lato-Light';
            line-height: 16px;
            max-width: 370px;
            vertical-align: bottom;
            margin-left: 15px;
        }
    }

    .container-code {
        margin-top: 30px;
        .title-code {
            @include LatoGrandeMobile;
        }
        .text-code {
            color: #000000;
            @include LatoMedianoMobile;
        }
        .content-items-code {
            margin-top: 10px;
            .text-items-code {
                @include ColorPrincipal;
                @include LatoMedianoMobile;
                border-bottom: 1px solid #8A8D8E;
            }
        }
    }
}
section.family {
    .column-content {
        margin-bottom: 0px;
    }
}

@media (max-width: 1200px) {
    section.productEstructure {
        .columnCategories {
            .box-relative {
                .btnDownloadCatalogue, .btnSearchAplication {
                    max-width: 100%;
                }
            }
        }
        .box-relative {
            .content-description {
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
}

@media (max-width: 1541px) {
    section.productEstructure {
        .columnCategories {
            right: -15px;
            padding-right: 15px;
        }
    }
}

@media (max-width: 991px) {
    section.productEstructure{
        .columnCategories{
            right: 0;
            padding-right: 30px;
            .listCategories{
                max-width: 100%;
                width: 100%;
            }
        }
    }
}

@media (max-width: 768px) {
    section.productEstructure{
        .box-relative {
            .btnSearchAplication {
                margin-top: 25px;
            }
        }
        .columnCategories {
            right: 0;
            padding-left: 15px;
            padding-right: 15px;
        }
        .shop-under-request {
            margin-bottom: 0;
            .text-under-request-mobile {
                margin-bottom: 15px;
                margin-left: 0;
                @include LatoMedianoMobile;
            }
        }
        .content-category {
            overflow-y: auto;
            .img-mobile {
                max-width: 182%;
            }
        }
    }
}



