section.contacto {
    background-image: url('/images/contact/banner_contact.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    .content-color {
        max-width: 100%;
        .container-contact {
            background: rgba(255, 255, 255, 0.8);
            padding-left: 125px;
            .row_contact {
                padding-top: 66px;
                padding-bottom: 66px;
                .container-info-form {
                    margin-top: 68px;
                    max-width: 425px;
                    width: 100%;
                    .contact_form {
                        .form-check-input {
                            margin-top: 5px;
                        }
                        input, textarea {
                            border: 0.5px solid #4F4F4F;
                            border-radius: 3px;
                            background: rgba(248, 248, 248, 0.8);
                        }
                        textarea {
                            min-height: 94px;
                        }
                        #ajaxSubmit{
                            margin-top: 40px;
                            max-width: 100%;
                        }
                        .text-info-contact {
                            margin-top: 25px;
                            @include LatoMedianoMobile;
                            @include ColorPrincipal;
                            a {
                                @include ColorSecundario;
                                &:hover {
                                    text-decoration: underline;
                                }
                            }
                        }
                    }

                }
            }
        }
        .container-contact-info {
            padding-left: 125px;
            padding-bottom: 40px;
            .content-contact-info {
                .title-content {
                    font-family: "Lato-Bold";
                    font-size: 18px;
                    line-height: 20px;
                    color: #FFFFFF;
                }
                .text-contact-info {
                    color: #FFFFFF;
                    @include LatoGrandeMobile;
                    font-size: 18px;
                    line-height: 27px;
                }
            }
        }
    }

    .container-info-items {
        .href-std {
            padding-bottom: 20px;
            font-size: 18px;
            font-weight: bold;
        }
        .title-redes {
            font-size: 18px;
            margin-bottom: 35px;
            margin-top: 50px;
        }
        .social-media-icons {
            margin-right: 55px;
            &:hover path {
                fill: #00bde0;
            }
        }
    }

    .contact_form {
        .hidden-google {
            font-size: 11px;
            padding-left: 18px;
            color: #a4a097;
            input[type=radio], input[type=checkbox] {
                margin-top: 2px;
            }
            a {
                font-size: 11px;
                text-decoration: underline;
                color: #000000;
                &:hover {
                    color: #E03828;
                }
            }
            &.last {
                margin-bottom: 24px;
            }
        }

        .hidden-google {
            font-family: "Lato-Light";
            color: #000000;
            padding-left: 0;
            line-height: 17px;
        }
        .btn-std {
            font-size: 25px;
            margin-top: 30px;
        }

        .status-envio {
            text-align: center;
            margin-top: 30px;
            font-size: 18px;
            font-family: "Lato-Light";
            line-height: 24px;
            display: none;
            &.active{
                display: block;
            }
            &.enviando {
                color: #016485;
            }
            &.enviado {
                color: #00bde0;
            }
            &.error {
                color: #e00022;
            }
        }
    }

    .container-info-direccion {
        .container-image {
            img {
                width: 100%;
            }
        }
        .container-description {
            p {
                font-size: 18px;
                &.info-principal {
                    margin-bottom: 50px;
                }
                &.horario {
                    margin-bottom: 30px;
                }
            }
        }
    }
    .form-group {
        &.animated-input {
            label {
                position: absolute;
                font-family: "Lato-Light";
                font-size: 18px;
                line-height: 20px;
                top: 12px;
                left: 16px;
                -webkit-transition: top .2s ease-in-out, font-size .2s ease-in-out;
                transition: top .2s ease-in-out,  font-size .2s ease-in-out;
                background: transparent;
                &.active {
                    top: -24px;
                    left: 0;
                    font-size: 18px;
                    line-height: 20px;
                }
            }
            input, select, textarea {
                font-family: "Lato-Light";
                font-size: 18px;
                line-height: 20px;
                height: 44px;
            }
        }
    }
    .form-check-group {
        padding-left: 15px;
    }
}
.infoContact{
    display: none;
    min-height: 197px;
    padding-left: 18px;
    padding-right: 18px;
    margin-top: 113px;
    background-image: url('/images/contact/info-contact.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    .titleInfoContact{
        font-family: "Lato-Light";
        font-size: 36px;
        line-height: 24px;
        @include ColorSecundario;
        margin-bottom: 25px;
    }
    .subtitleInfoContact{
        @include LatoGrandeMobile;
    }
    .btnInfoContact {
        @include LatoMedianoDesktop;
        line-height: 24px;
        width: 100%;
        max-width: 110px;
        padding: 10px 0px;
    }
}
.infoContactProduct {
    padding-left: 18px;
    padding-right: 18px;
    margin-top: 100px;
    .subtitleInfoContact{
        @include LatoGrandeMobile;
        max-width: 473px;
    }
    .btnInfoContact {
        @include LatoMedianoDesktop;
        line-height: 24px;
        width: 100%;
        max-width: 110px;
        padding: 10px 0px;
    }
}

@media (max-width: 991px) {
    section.contacto {
        .content-color {
            margin: 0;
            .container-contact {
                background: rgba(255, 255, 255, 0.6);
                padding-left: 15px;
                .row_contact {
                    padding-top: 44px;
                    padding-bottom: 44px;
                    .container-info-form {
                        margin-top: 30px;
                    }
                }
            }
            .container-contact-info {
                padding-top: 80px;
                padding-left: 15px;
            }
        }
    }
}

@media (max-width: 768px) {
    .infoContact, .infoContactProduct{
        min-height: 257px;
        padding-left: 0;
        padding-right: 0;
        padding-top: 20px;
        padding-bottom: 20px;
        .subtitleInfoContact{
            font-size: 21px;
            line-height: 24px;
        }
    }
    .infoContactProduct {
        min-height: auto;
        .btnInfoContact {
            margin-left: 0;
        }
    }
}
