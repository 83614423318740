.table-std {
    overflow: auto;
    .text-header {
        .containerWithBorder{
            height: 100%;
            padding-bottom: 13px;
            border-bottom: 1px solid #E03828;
        }
        span {
            @include ColorSecundario;
            @include LatoGrandeMobile;
        }
    }
    .text-content {
        .containerWithBorder{
            height: 100%;
            border-bottom: 1px solid #8A8D8E;
        }
        p {
            font-size: 18px;
            font-family: 'Lato-Light';
            line-height: 20px;
            @include ColorPrincipal;
            padding-bottom: 15px;
            padding-top: 15px;
        }
        a {
            font-size: 18px;
            font-family: "Lato-Light";
            line-height: 20px;
        }
        .filtro {
            border-bottom: 1px solid #8A8D8E;
        }
        .last {
            border-bottom: none;
        }
    }
}

@media (max-width: 991px) {
    .table-std {
        overflow: auto;
        .row-table {
            width: 894px;
        }
        .text-content {
            p {
                word-break: break-word;
            }
        }
    }
}
