section.buscador {
    margin-bottom: 286px;
    .content-title {
        .title-search {
            margin-top: 66px;
            font-size: 52px;
            line-height: 52px;
            font-family: "Lato-Light";
            margin-bottom: 17px;
        }
        .subtitle-search {
            @include LatoGrandeMobile;
            @include ColorPrincipal;
        }
    }
    .content-search {
        margin-top: 33px;
        .title-search {
            font-size: 40px;
            line-height: 42px;
            font-family: "Lato-Light";
            @include ColorPrincipal;
        }
        .subtitle-search {
            @include LatoMedianoDesktop;
        }
        .btn-search {
            max-width: 133px;
            width: 100%;
            padding: 10px 0px;
        }
        .search {
            position: relative;
            .absolute-search-product {
                position: absolute;
                left: -140px;
                right: 0;
                background-image: url('/images/search/image1_search.jpg');
                background-repeat: no-repeat;
                background-size: cover;
                padding-top: 16px;
                padding-bottom: 37px;
                padding-right: 102px;
                padding-left: 140px;
                max-width: 629px;
                .form-search-product {
                    margin-top: 48px;
                    .input-search-product {
                        max-width: 217px;
                        width: 100%;
                        height: auto;
                        font-family: "Lato-Light";
                        font-size: 16px;
                        line-height: 24px;
                        color: #4F4F4F;
                        &::placeholder {
                            @include LatoMedianoMobile;
                            @include ColorTerciario;
                        }
                    }
                }
            }
            .absolute-search-aplication{
                position: absolute;
                background-image: url('/images/search/image2_search.jpg');
                background-repeat: no-repeat;
                background-size: cover;
                padding-top: 16px;
                padding-right: 125px;
                padding-left: 20px;
                padding-bottom: 37px;
                left: 0;
                right: -131px;
                .form-group {
                    margin-bottom: 0;
                    max-width: 217px;
                    width: 100%;
                    label {
                        @include LatoMedianoDesktop;
                    }
                    .input-aplication {
                        height: 48px;
                        width: 100%;
                        max-width: 217px;
                        @include LatoMedianoMobile;
                        @include ColorTerciario;
                        .option-aplication {
                            font-family: "Lato-Light";
                            font-weight: 400;
                            font-size: 18px;
                            line-height: 22px;
                        }
                        &::placeholder {
                            font-family: "Lato-Light";
                            font-weight: 400;
                            font-size: 18px;
                            line-height: 22px;
                        }
                    }
                    .select-aplication {
                        appearance: none;
                        background:  url('/img/icons/down.svg') no-repeat scroll 182px center transparent;
                        background-color: #fff;
                        background-size: 24px;
                        optgroup[label] {
                            font-family: "Lato-Light";
                            font-weight: 400;
                            font-size: 18px;
                            line-height: 22px;
                            @include ColorTerciario;
                            .option-aplication {
                               font-family: "Lato-Light";
                               font-weight: 400;
                               font-size: 18px;
                               line-height: 22px;
                            }
                        }
                    }
                }
                .btn-search-aplication {
                    height: 48px;
                }
            }
        }
    }
    .content-results-products {
        margin-top: 271px;
        .title-results {
            font-size: 40px;
            font-family: "Lato-Light";
            line-height: 42px;
            @include ColorSecundario;
        }
        .name-product {
            font-family: "Lato-Light";
            font-size: 18px;
            line-height: 20px;
            margin-top: 16px;
            margin-bottom: 16px;
            color: #000000;
            .link-product {
                font-size: 20px;
                line-height: 24px;
                font-family: "Lato-Regular";
                float: right;
                &:hover {
                    .imgCategory {
                        path {
                            stroke: #E03828;
                        }
                    }
                }
            }
        }
        .line-item {
            border-top: 1px solid #8A8D8E;
        }
    }
    .no-results {
        @include LatoGrandeMobile;
        @include ColorPrincipal;
        text-align: center;
        margin-top: 25px;
    }
    .content-results-aplications {
        margin-top: 72px;
        .title-results {
            font-size: 40px;
            font-family: "Lato-Light";
            line-height: 42px;
            @include ColorSecundario;
        }
    }
    .margin-aplications {
        margin-top: 271px;
    }
}
@media (max-width: 1200px) {
    section.buscador {
        .content-search {
            .search {
                margin-bottom: 259px;
                .absolute-search-product {
                    left: -30px;
                    width: 100vw;
                    max-width: 100vw;
                    padding-left: 15px;
                    padding-right: 15px;
                    .form-search-product {
                        margin-top: 32px;
                        .input-search-product {
                            max-width: 100%;
                        }
                        .btn-search {
                            margin-left: 15px;
                        }
                    }
                }
                .absolute-search-aplication {
                    left: -30px;
                    padding-right: 15px;
                    padding-left: 15px;
                    width: 100vw;
                    .subtitle-search {
                        font-size: 18px;
                    }
                    .form-group {
                        .input-aplication {
                            width: 100%;
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 991px) {
    section.buscador{
        .content-search{
            .search{
                .absolute-search-product{
                    left: -15px;
                }
                .absolute-search-aplication {
                    left: -15px;
                }
            }
        }
    }
}
@media (max-width: 768px) {
    section.buscador {
        margin-bottom: 50px;
        .content-title {
            .title-search {
                font-size: 36px;
            }
        }
        .content-search {
            .title-search {
                font-size: 30px;
            }
            .search {
                margin-bottom: 50px;
                .absolute-search-product{
                    position: relative;
                }
                .absolute-search-aplication {
                    position: relative;
                    .form-group {
                        margin-bottom: 20px;
                        max-width: 100%;
                        .select-aplication {
                            background: url(/img/icons/down.svg) no-repeat scroll right center transparent;
                            background-color: #fff;
                            background-size: 24px;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            appearance: none;
                        }
                    }
                    .btn-search {
                        max-width: 100%;
                        width: 100%;
                    }
                }
            }
        }
        .content-results-products {
            margin-top: 0px;
            .title-results {
                font-size: 32px;
            }
        }
        .content-results-aplications {
            .title-results {
                font-size: 32px;
            }
            table {
                display: block;
                overflow-x: auto;
                white-space: nowrap;
                -webkit-overflow-scrolling: touch;
                th {
                    font-size: 18px;
                }
                th, td {
                    padding: 0.5em 0.40em;
                }
            }
        }
    }
}
