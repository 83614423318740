section.cart.step-3 {
    padding-top: 38px;
    .text-link {
        .link-go-back {
            @include ColorPrincipal;
            @include LatoPequenoDesktop;
            text-decoration: underline;
        }
    }
    .content-step-3 {
        margin-top: 20px;
        .item-container{
            background-color: #ffffff;
            border: 0.5px solid #ffffff;
            border-radius: 3px;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 15px;
            padding-right: 15px;
            padding-top: 25px;
            padding-bottom: 25px;
            max-width: 364px;
            height: 90px;
            margin-bottom: 10px;
            cursor: pointer;
            &:hover {
                border: 0.5px solid #E03828;
                .select-payment {
                    path {
                        stroke: #E03828;
                    }
                }
                .first-container {
                    .label-payment {
                        color: #E03828;
                    }
                }
            }
            .select-payment {
                path {
                    stroke: #000000;
                }
            }
            .first-container{
                display:flex;
                align-items: center;
                .label-payment {
                    margin-left: 26px;
                    @include LatoMedianoDesktop;
                }
            }
        }
        .item-active {
            border: 0.5px solid #E03828;
            .select-payment {
                path {
                    stroke: #E03828;
                }
            }
            .first-container {
                .label-payment {
                    color: #E03828;
                }
            }
        }
        .container-resumen{
            margin-top:20px;
            .values-pedido{
                margin-top: 20px;
            }
        }
        .container-payments{
            margin-bottom: 43px;
            .subtitle_step {
                @include LatoMedianoMobile;
                margin-top: 5px;
                margin-bottom: 30px;
            }
            .payment-button-one{
                max-width: 368px;
                margin: auto;
                margin-top: 33px;
            }
            .error-form{
                color:red;
                position: fixed;
                top: 230px;
            }
        }
        .content-modify-datos {
            margin-top: 104px;
            .modify-datos {
                @include ColorPrincipal;
                @include LatoGrandeMobile;
                max-width: 779px;
                width: 100%;
                .modify {
                    a {
                        @include ColorPrincipal;
                        @include LatoPequenoDesktop;
                        text-decoration: underline;
                        &:hover {
                            @include ColorSecundario;
                        }
                    }
                }
            }
            .content-info {
                background-color: #FFFFFF;
                padding: 16px;
                max-width: 364px;
                width: 100%;
                .title_std {
                    margin-bottom: 10px;
                }
                .text-user {
                    margin-bottom: 5px;
                    @include LatoMedianoMobile;
                    @include ColorPrincipal;
                }
            }
        }
    }
}

@media (max-width: 991px) {
    section.cart.step-3 {
        margin-bottom: 157px;
    }
}

@media (max-width: 768px) {
    section.cart.step-3 {
        .content-step-3 {
            .item-container {
                max-width: 100%;
            }
        }
    }
}

@media (max-width: 576px) {
    section.cart.step-3 {
        .content-step-3 {
            .content-modify-datos {
                .container-datos {
                    background-color: #FFFFFF;
                    padding-top: 15px;
                    padding-bottom: 15px;
                    margin-bottom: 30px;
                    .content-info {
                        background-color: none;
                        padding: 0;
                    }
                }
            }
        }
    }
}
