.containerImage {
    background-repeat: no-repeat;
    background-size: cover;
    height: 400px;
    margin-bottom: 34px;
    .containerImageColor {
        height: 100%;
        background: rgba(255, 255, 255, 0.8);
        max-width: 828px;
        .productDetail{
            height: 100%;
            .rowContentBanner {
                height: 100%;
                .title-banner-home {
                    color: #8A8D8E !important;
                    font-size: 48px;
                    font-family: "Lato-Light";
                    line-height: 48px;
                }
                .subtitle-banner-home {
                    font-family: 'Lato-Light';
                    font-size: 24px;
                    line-height: 24px;
                    color: #000000 !important;
                }
                .subtitleBanner {
                    @include ColorPrincipal;
                }
                .subtitleBannerAplication {
                    color: #000000;
                    @include LatoMedianoDesktop;
                }
                .titleBanner {
                    font-size: 48px;
                    font-family: "Lato-Light";
                    line-height: 48px;
                    @include ColorSecundario;
                }
                .titleBannerAplication {
                    @include ColorPrincipal;
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .containerImage {
        .containerImageColor {
            .productDetail {
                .rowContentBanner {
                    .titleBanner  {
                        font-family: "Lato-Light";
                        font-size: 42px;
                        line-height: 42px;
                    }
                    .subtitleBanner {
                        font-family: "Lato-Light";
                        font-size: 21px;
                        line-height: 21px;
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .productEstructure, .applications {
        .containerImage {
            .containerImageColor {
                height: auto;
                .rowContentBanner {
                    margin-top: 30px;
                }
            }
        }
    }
    .containerImage {
        .containerImageColor {
            height: 196px;
            .productDetail {
                .rowContentBanner {
                    height: 100%;
                    .title-banner-home {
                        font-family: "Lato-Light";
                        font-size: 42px;
                        line-height: 43px;
                    }
                    .subtitle-banner-home {
                        font-family: 'Lato-Light';
                        font-size: 21px;
                        line-height: 21px;
                    }
                }
            }
        }
    }
}

