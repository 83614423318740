body {
    background-color: #FFFFFF;
}
/* FUENTES */
@font-face {
    font-family: 'Lato-Regular';
    src: url('/fonts/Lato-Regular.ttf');
    src: url('/fonts/Lato-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Lato-Bold';
    src: url('/fonts/Lato-Bold.ttf');
    src: url('/fonts/Lato-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Lato-Thin';
    src: url('/fonts/Lato-Thin.ttf');
    src: url('/fonts/Lato-Thin.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Lato-Italic';
    src: url('/fonts/Lato-Italic.ttf');
    src: url('/fonts/Lato-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Lato-Light';
    src: url('/fonts/Lato-Light.ttf');
    src: url('/fonts/Lato-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/* COLORES */
@mixin ColorPrincipal() {
    color: #8A8D8E;
}
@mixin ColorSecundario() {
    color: #E03828;
}
@mixin ColorTerciario() {
    color: #4F4F4F;
}

/* ESTILOS DE FUENTE */
@mixin LatoExtraGrande() {
    font-family: "Lato-Light";
    font-size: 48px;
    line-height: 48px;
    @media (max-width: 991px) {
        font-size: 36px;
    }
}
@mixin LatoGrande() {
    font-family: "Lato-Light";
    font-size: 40px;
    line-height: 45px;
    @media (max-width: 991px) {
        font-size: 32px;
    }
}

/* FUENTES A CAMBIAR */

@mixin LatoXlDesktop() {
    font-family: "Lato-Light";
    font-size: 48px;
    line-height: 48px;
}
@mixin LatoGrandeDesktop() {
    font-family: 'Lato-Light';
    font-size: 32px;
    line-height: 38px;
}
@mixin LatoGrandeMobile() {
    font-family: 'Lato-Light';
    font-size: 24px;
    line-height: 24px;
}
@mixin LatoMedianoDesktop() {
    font-family: 'Lato-Light';
    font-size: 20px;
    line-height: 26px;
}
@mixin LatoMedianoMobile() {
    font-family: 'Lato-Light';
    font-size: 16px;
    line-height: 24px;
}
@mixin LatoPequenoDesktop() {
    font-family: 'Lato-Light';
    font-size: 14px;
    line-height: 16px;
}
@mixin LatoPequenoMobile() {
    font-family: 'Lato-Light';
    font-size: 12px;
    line-height: 14px;
}

@mixin LatoRegularMenu() {
    font-family: 'Lato-Regular';
    font-size: 14px;
    line-height: 16px;
}
.title-std {
    font-family: 'Lato-Light';
    font-size: 48px;
    line-height: 48px;
}
.subtitle-std {
    font-family: 'Lato-Light';
    font-size: 24px;
    line-height: 24px;
}

/* ESTILOS DE BOTONES */
.btn-std {
    outline: none !important;
    border: none;
    box-shadow: none;
    color: #FFFFFF;
    padding: 10px 30px;
    cursor: pointer;
    background-color: #8A8D8E;
    line-height: 24px;
    text-align: center;
    border-radius: 3px;
    @include LatoMedianoDesktop;
    font-family: "Lato-Regular";
    &:hover {
        background-color: #616363;
        color: #FFFFFF;
        text-decoration: none;
    }
    &.small {
        padding-left: 14px;
        padding-right: 14px;
        font-size: 12px;
    }
    &.medium {
        padding-left: 14px;
        padding-right: 14px;
        font-size: 16px;
    }
    &.big {
        padding-left: 74px;
        padding-right: 74px;
        font-size: 24px;

    }
    &.no-active {
        background-color: #c6c2b6;
        cursor: unset;
    }
}
.btn-red {
    padding: 10px 30px;
    background-color: #E03828;
    border-radius: 3px;
    outline: none !important;
    border: none;
    box-shadow: none;
    color: #FFFFFF;
    cursor: pointer;
    text-align: center;
    font-family: "Lato-Regular";
    font-size: 20px;
    line-height: 24px;
    &:hover {
        background-color: #b32d20;
        color: #FFFFFF;
    }
}

.link-general {
    color: #8A8D8E;
    text-decoration: none;
    @include LatoMedianoMobile;
    cursor: pointer;
    &:hover {
        text-decoration: none;
        color: #E03828;
    }
}

.link-red {
    color: #E03828;
    text-decoration: none;
    cursor: pointer;
    &:hover {
        text-decoration: none;
        color: #b32d20;
    }
}

/* OTROS ESTILOS */

.background-product-1001 {
    background: rgba(224, 56, 40, 0.8);
}
.background-product-1002 {
    background: rgba(244, 122, 36, 0.8);
}
.background-product-1003 {
    background: rgba(231, 183, 22, 0.8);
}
.background-product-1004 {
    background: rgba(102, 188, 41, 0.8);
}
.background-product-1005 {
    background: rgba(0, 168, 180, 0.8);
}
.background-product-1006 {
    background: rgba(0, 173, 239, 0.8);
}


.background-product-with-hover-1001 {
    background: rgba(224, 56, 40, 0.8);
    &:hover {
        background-color: #b32d20;
        color: #FFFFFF;
    }
}
.background-product-with-hover-1002 {
    background: rgba(244, 122, 36, 0.8);
    &:hover {
        background-color: #c66f1e;
        color: #FFFFFF;
    }
}
.background-product-with-hover-1003 {
    background: rgba(231, 183, 22, 0.8);
    &:hover {
        background-color: #b8a10f;
        color: #FFFFFF;
    }
}
.background-product-with-hover-1004 {
    background: rgba(102, 188, 41, 0.8);
    &:hover {
        background-color: #4d9b1f;
        color: #FFFFFF;
    }
}
.background-product-with-hover-1005 {
    background: rgba(0, 168, 180, 0.8);
    &:hover {
        background-color: #00919c;
        color: #FFFFFF;
    }
}
.background-product-with-hover-1006 {
    background: rgba(0, 173, 239, 0.8);
    &:hover {
        background-color: #0096d3;
        color: #FFFFFF;
    }
}

.color-product-1001 {
    color: #E03828 !important;
}
.color-product-1002 {
    color: #F47A24 !important;
}
.color-product-1003 {
    color: #E7B716 !important;
}
.color-product-1004 {
    color: #66BC29 !important;
}
.color-product-1005 {
    color: #00A8B4 !important;
}
.color-product-1006 {
    color: #00ADEF !important;
}

.background-color-product-1001 {
    background-color: #E03828 !important;
}
.background-color-product-1002 {
    background-color: #F47A24 !important;
}
.background-color-product-1003 {
    background-color: #E7B716 !important;
}
.background-color-product-1004 {
    background-color: #66BC29 !important;
}
.background-color-product-1005 {
    background-color: #00A8B4 !important;
}
.background-color-product-1006 {
    background-color: #00ADEF !important;
}

.border-product-1001 {
    border-top: 1px solid #E03828;
}
.border-product-1002 {
    border-top: 1px solid #F47A24;
}
.border-product-1003 {
    border-top: 1px solid #E7B716;
}
.border-product-1004 {
    border-top: 1px solid #66BC29;
}
.border-product-1005 {
    border-top: 1px solid #00A8B4;
}
.border-product-1006 {
    border-top: 1px solid #00ADEF;
}


.border-bottom-product-1001 {
    border-bottom: 1px solid #E03828 !important;
}
.border-bottom-product-1002 {
    border-bottom: 1px solid #F47A24 !important;
}
.border-bottom-product-1003 {
    border-bottom: 1px solid #E7B716 !important;
}
.border-bottom-product-1004 {
    border-bottom: 1px solid #66BC29 !important;
}
.border-bottom-product-1005 {
    border-bottom: 1px solid #00A8B4 !important;
}
.border-bottom-product-1006 {
    border-bottom: 1px solid #00ADEF !important;
}

.border-bottom-line-product-1001 {
    border-bottom: 2px solid #E03828 !important;
}
.border-bottom-line-product-1002 {
    border-bottom: 2px solid #F47A24 !important;
}
.border-bottom-line-product-1003 {
    border-bottom: 2px solid #E7B716 !important;
}
.border-bottom-line-product-1004 {
    border-bottom: 2px solid #66BC29 !important;
}
.border-bottom-line-product-1005 {
    border-bottom: 2px solid #00A8B4 !important;
}
.border-bottom-line-product-1006 {
    border-bottom: 2px solid #00ADEF !important;
}

.btn-product-1001 {
    padding: 10px 30px;
    background-color: #E03828;
    border-radius: 3px;
    outline: none !important;
    border: none;
    box-shadow: none;
    color: #FFFFFF;
    cursor: pointer;
    text-align: center;
    @include LatoMedianoDesktop;
    font-family: "Lato-Regular";
    &:hover {
        background-color: #b32d20;
        color: #FFFFFF;
    }
}
.btn-product-1002 {
    padding: 10px 30px;
    background-color: #F47A24;
    border-radius: 3px;
    outline: none !important;
    border: none;
    box-shadow: none;
    color: #FFFFFF;
    cursor: pointer;
    text-align: center;
    @include LatoMedianoDesktop;
    font-family: "Lato-Regular";
    &:hover {
        background-color: #c66f1e;
        color: #FFFFFF;
    }
}
.btn-product-1003 {
    padding: 10px 30px;
    background-color: #E7B716;
    border-radius: 3px;
    outline: none !important;
    border: none;
    box-shadow: none;
    color: #FFFFFF;
    cursor: pointer;
    text-align: center;
    @include LatoMedianoDesktop;
    font-family: "Lato-Regular";
    &:hover {
        background-color: #b8a10f;
        color: #FFFFFF;
    }
}
.btn-product-1004 {
    padding: 10px 30px;
    background-color: #66BC29;
    border-radius: 3px;
    outline: none !important;
    border: none;
    box-shadow: none;
    color: #FFFFFF;
    cursor: pointer;
    text-align: center;
    @include LatoMedianoDesktop;
    font-family: "Lato-Regular";
    &:hover {
        background-color: #4d9b1f;
        color: #FFFFFF;
    }
}
.btn-product-1005 {
    padding: 10px 30px;
    background-color: #00A8B4;
    border-radius: 3px;
    outline: none !important;
    border: none;
    box-shadow: none;
    color: #FFFFFF;
    cursor: pointer;
    text-align: center;
    @include LatoMedianoDesktop;
    font-family: "Lato-Regular";
    &:hover {
        background-color: #00919c;
        color: #FFFFFF;
    }
}
.btn-product-1006 {
    padding: 10px 30px;
    background-color: #00ADEF;
    border-radius: 3px;
    outline: none !important;
    border: none;
    box-shadow: none;
    color: #FFFFFF;
    cursor: pointer;
    text-align: center;
    @include LatoMedianoDesktop;
    font-family: "Lato-Regular";
    &:hover {
        background-color: #0096d3;
        color: #FFFFFF;
    }
}

.textCategory-1001 {
    font-family: 'Lato-Light';
    font-size: 24px;
    line-height: 24px;
    a {
        @include ColorPrincipal;
        &:hover {
            color: #E03828;
            text-decoration: none;
            path {
                stroke: #E03828;
            }
        }
    }
}
.textCategory-1002 {
    font-family: 'Lato-Light';
    font-size: 24px;
    line-height: 24px;
    a {
        @include ColorPrincipal;
        &:hover {
            color: #F47A24;
            text-decoration: none;
            path {
                stroke: #F47A24;
            }
        }
    }
}
.textCategory-1003 {
    font-family: 'Lato-Light';
    font-size: 24px;
    line-height: 24px;
    a {
        @include ColorPrincipal;
        &:hover {
            color: #E7B716;
            text-decoration: none;
            path {
                stroke: #E7B716;
            }
        }
    }
}
.textCategory-1004 {
    font-family: 'Lato-Light';
    font-size: 24px;
    line-height: 24px;
    a {
        @include ColorPrincipal;
        &:hover {
            color: #66BC29;
            text-decoration: none;
            path {
                stroke: #66BC29;
            }
        }
    }
}
.textCategory-1005 {
    font-family: 'Lato-Light';
    font-size: 24px;
    line-height: 24px;
    a {
        @include ColorPrincipal;
        &:hover {
            color: #00A8B4;
            text-decoration: none;
            path {
                stroke: #00A8B4;
            }
        }
    }
}
.textCategory-1006 {
    font-family: 'Lato-Light';
    font-size: 24px;
    line-height: 24px;
    a {
        @include ColorPrincipal;
        &:hover {
            color: #00ADEF;
            text-decoration: none;
            path{
                stroke: #00ADEF;
            }
        }
    }
}
.imgDown-product-1001 {
    g {
        filter: none;
    }
    path {
        stroke: #E03828;
    }
}
.imgDown-product-1002 {
    g {
        filter: none;
    }
    path {
        stroke: #F47A24;
    }
}
.imgDown-product-1003 {
    g {
        filter: none;
    }
    path {
        stroke: #E7B716;
    }
}
.imgDown-product-1004 {
    g {
        filter: none;
    }
    path {
        stroke: #66BC29;
    }
}
.imgDown-product-1005 {
    g {
        filter: none;
    }
    path {
        stroke: #00A8B4;
    }
}
.imgDown-product-1006 {
    g {
        filter: none;
    }
    path {
        stroke: #00ADEF;
    }
}

.views_steps {
    background-color: #fbfaf8;
}
.title_section {
    @include ColorSecundario;
    @include LatoExtraGrande;
}
.subtitle_section {
    @include ColorPrincipal;
    @include LatoGrandeMobile;
    @media (max-width : 991px) {
        font-family: "Lato-Light";
        font-size: 18px;
        line-height: 24px;
    }
}
.color-special-text {
    color:#a4a097;
}
input {
    border-radius: 3px;
    border: 1px solid #fff;
}
.plus-qty, .less-qty{
    width: 24px;
    height: 24px;
    cursor: pointer;
}
.eye-password{
    position: absolute;
    top: 10px;
    right: 8px;
    cursor: pointer;
    img, svg {
        path {
            stroke: #8A8D8E;
        }
    }
}
.lock-password{
    position: absolute;
    top: 10px;
    right: 8px;
    img, svg {
        path {
            stroke: #8A8D8E;
        }
    }
}
.text-lg-std {
    font-size: 34px;
}
.text-md-std{
    font-size: 24px;
}
.text-sm-std{
    font-size: 14px;
}
.container-fluid {
    max-width: 1700px;
    padding-left: 30px;
    padding-right: 30px;
}

.container-margin {
    margin-top: 170px !important;
}

.container-fluid-banner {
    max-width: 1700px;
    padding-left: 130px;
    padding-right: 10px;
}

.label-upper-transparent{
    background-color: transparent!important;
    &.active {
        color: #242731 !important;
    }
}
.is-invalid-div{
    border: 1px solid#e3342f;;
}
.btn-disabled{
    background-color: grey!important;
    opacity: 0.5!important;
}
.cursor-ilegal{
    cursor: not-allowed!important;
}
.ancle-disabled{
    pointer-events: none;
}
.cursor-pointer{
    cursor: pointer;
}

.href-std {
    color: #282828;
    &:hover {
        color: #00bde0;
    }
}

.error-form {
    font-family: 'Lato-Light';
    color: red;
    font-size: 12px;
    margin-bottom: 8px;
    display: none;
    &.show {
        display: block;
    }
}

.status-envio {
    text-align: center;
    margin-top: 30px;
    font-size: 18px;
    font-family: "Lato-Light";
    line-height: 24px;
    display: none;
    &.active{
        display: block;
    }
    &.enviando {
        color: #016485;
    }
    &.enviado {
        color: #00bde0;
    }
    &.error {
        color: #e00022;
    }
}

.grecaptcha-badge {
    visibility: hidden !important;
}
.checks-form .form-check-group, .checks-form small{
    font-size: 11px;
    padding-left: 18px;
    color: #a4a097;

}
.container-minipopup-info {
    position: relative;
    .element-minipopup {
        display: none;
        position: absolute;
        bottom: 39px;
        width: 150px;
        right: -53px;
        background-color: #e1dfdd;
        color: #000;
        padding: 10px;
        p {
            color: grey;
            font-weight: 500;
            font-size: 11px;
            line-height: 15px;
        }

        .decoration {
            width: 26px;
            height: 12px;
            transform: rotate(45deg);
            position: relative;
            top: 9px;
            left: 32px;
            background-color: #e1dfdd;
        }
    }

    &:hover {
        .element-minipopup {
            display: block;
        }
    }
}
.container-newsletter {
    .form-check-label{
        font-size: 12px;
    }
    .form-group{
        .form-control{
            border-radius: 0.25rem 0px 0px 0.25rem;
        }
    }
    .btn-std{
        margin-top: 8px;
        line-height: 19px;
    }
}

.form-check-label {
    @include LatoPequenoDesktop;
    color: #000000;
    line-height: 20px;
    a {
        color: #000000;
        text-decoration: underline;
        &:hover {
            @include ColorSecundario;
        }
    }
}

@media (max-width: 991px) {
    .container-fluid {
        padding-left: 15px;
        padding-right: 15px;
    }
    .container-margin{
        margin-top: 120px !important;
    }
}

@media (max-width: 768px) {
    .container-fluid-banner {
        padding-left: 15px;
        padding-right: 15px;
    }
    .text-lg-std {
        font-size: 24px;
    }
    .text-md-std{
        font-size: 14px;
    }
    .text-sm-std{
        font-size: 13px;
    }
}

@media (max-width: 414px) {
    .title_std {
        font-size: 24px;
    }
    .text-lg-std {
        font-size: 18px;
    }
    .text-md-std{
        font-size: 14px;
    }
    .text-sm-std{
        font-size: 12px;
    }

}
