.newsletter-container {
    position: relative;
    .text-error{
        font-size:11px;
        position: absolute;
        top: -15px;
    }
    .input-newsletter{
        border:1px solid lightgrey;
    }
}
