.header{
    padding-top: 15px;
    padding-bottom: 15px;
    width: 100%;
    background-color: white;
    top: 0;
    position: fixed;
    z-index: 100;
    transition: top 0.5s ease;
    &.header-hide {
        top: -112px !important;
    }
    &.scroll-active {
        padding-top: 5px;
        padding-bottom: 5px;
        background-color: white;
        top: 0px;
        .container-image {
            .logo-image {
                width: 92px;
                transition: width 1.0s ease;
            }
        }
    }
    .container-background-shoppingBag{
        height: 100vh;
        width: 100vw;
        position: absolute;
        z-index: 0;
        right: 0;
        top: 0;
        background-color: rgba(31,35,35,.59);
    }
    .container-image{
        .logo-image{
            width: 145px;
            transition: width 0.5s ease;
        }
    }
    .container-shoppingbag{
        .bag{
            position: relative;
            .bag-image{
                &:hover{
                    cursor: pointer;
                }
            }
            .container-count {
                border: 1px solid white;
                border-radius: 20px;
                width: 15px;
                height: 15px;
                background-color: white;
                position: absolute;
                bottom: -4px;
                right: 14px;
                & > p {
                    text-align: center;
                    vertical-align: middle;
                    margin-bottom: 20px;
                    font-weight: bold;
                    font-size: 10px;
                }
            }
        }
        .content-bag{
            display: none;
            background-color: #FFFFFF;
            width: 381px;
            right: 0;
            top: 0;
            height: 100vh;
            position: fixed;
            z-index: 4;
            .decoration{
                width: 26px;
                height: 12px;
                transform: rotate(45deg);
                position: relative;
                top: -1px;

                left: 419px;
                background-color: #f2f2f2;
            }
            .title-shopping-bag {
                @include LatoGrandeMobile;
                @include ColorSecundario;
                margin-top: 56px;
                .img-close-bag {
                    .img-bag {
                        width: 26px;
                        filter: brightness(0) saturate(100%) invert(25%) sepia(64%) saturate(2730%) hue-rotate(348deg) brightness(97%) contrast(89%);
                    }
                }
            }
        }
    }
    .icon-header {
        width: 24px;
        height: 24px;
        cursor: pointer;
        path {
            stroke: #8A8D8E;
        }
        &:hover {
            path {
                stroke: #E03828;
            }
        }
    }
    .line-container {
        margin-top: 21px;
        .line-item {
            border-top: 1px solid #d6d0c4;
            height: 1px;
            margin: 0;
            width: 100%;
        }
    }
    .container-menu{
        .image-menu{
            width: 92px;
        }
        .hamburguer-image {
            width: 33px;
            margin-left: 38px;
        }
        .menu-mobile{
            position: fixed;
            right: -100%;
            top: 0px;
            width: 78%;
            height: 100%;
            z-index: 99;
            padding: 5px;
            background-color: #f2f2f2;
            padding-left: 15px;
            padding-right: 15px;
            .icons-items {
                margin-top: 30px;
                margin-bottom: 30px;
                .title-section-header {
                    font-family: "Lato-Bold";
                    font-size: 16px;
                    line-height: 20px;
                }
                .img-close {
                    width: 33px;
                }
            }
            &.active {
                position: fixed;
                top: 0;
                right: 0;
                transition: right 0.5s;
                z-index: 999;
                overflow: scroll;
            }
            &.noactive {
                position: fixed;
                top: 0;
                right: -100%;
                transition: right 1.5s;
            }
            .go-back {
                .link-menu-mobile-principal {
                    color: #000000;
                    border-bottom: 1px solid black;
                    padding-bottom: 10px;
                    span {
                        @include LatoMedianoMobile;
                    }
                }
                .img-down {
                    transform: rotate(265deg);
                }
            }
            .link-items {
                margin-top: 30px;
                .with-categories {
                    .title-category {
                        font-family: "Lato-Regular";
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }
            .menu-principal-mobile {
                .nav-products, .nav-services {
                    .rotate {
                        transform: rotate(-90deg);
                    }
                }
            }
        }
    }
    .link-menu {
        @include LatoPequenoDesktop;
        color: #4F4F4F;
        text-transform: uppercase;
        font-family: "Lato-Regular";
        margin-left: 45px;
        cursor: pointer;
        &:hover {
            @include ColorSecundario;
            text-decoration: underline;
            .img-down {
                path {
                    stroke: #E03828;
                }
            }
        }
        &.active {
            @include ColorSecundario;
            text-decoration: underline;
            .img-down {
                path {
                    stroke: #E03828;
                }
            }
        }
    }
    .second-column-group {
        margin-left: 100px;
        .btnSearchMenu {
            display: flex;
            padding: 5px 10px;
            font-size: 15px;
            font-family: "Lato-Regular";
            line-height: 24px;
        }
        .img-search{
            margin-left: 36px;
        }
        .icons-group-header {
            margin-left: 10px;
            margin-right: 10px;
        }
    }
    .img-down {
        width: 20px;
        height: 20px;
        path {
            stroke: #4F4F4F;
        }
    }
    .rotate {
        transform: rotate(-180deg);
    }
    .img-down-hover {
        width: 20px;
        height: 20px;
        filter: brightness(0) saturate(100%) invert(28%) sepia(57%) saturate(2374%) hue-rotate(343deg) brightness(96%) contrast(96%);
        display: none;
    }
    .custom-dropdown {
        width: 100%;
        border-color: #FFFFFF;
        box-shadow: 4px 4px 9px rgba(0, 0, 0, 0.06);
        .list-aplications-header {
            list-style: none;
            .item-aplications-header {
                font-family: "Lato-Light";
                font-size: 16px;
                line-height: 24px;
                @include ColorPrincipal;
                margin-top: 10px;
                a {
                    font-family: "Lato-Regular";
                    font-size: 16px;
                    line-height: 24px;
                    @include ColorPrincipal;
                    &:hover {
                        text-decoration: none;
                        @include ColorSecundario;
                    }
                    &:hover .img-down-hover {
                        display: block;
                    }
                    &:hover .img-down {
                        display: none;
                    }
                }
            }
            .title-aplication-category {
                @include LatoMedianoMobile;
                font-family: "Lato-Regular";
                .text-aplication-category {
                    border-bottom: 1px solid #8A8D8E;
                    padding-bottom: 4px;
                }
            }
        }
        .list-aplications-header-no-category {
            margin-top: 33px;
        }
    }
    .custom-dropdown-general {
        width: 264px;
        padding-top: 3px;
        padding-bottom: 0px;
        border-color: #FFFFFF;
        box-shadow: 4px 4px 9px rgba(0, 0, 0, 0.06);
        border-radius: 0px 10px 10px 10px;
        margin-top: 0;
        .list-general {
            list-style: none;
            .item-general {
                font-family: "Lato-Regular";
                font-size: 16px;
                line-height: 24px;
                @include ColorPrincipal;
                margin-top: 10px;
                a {
                    font-family: "Lato-Regular";
                    font-size: 16px;
                    line-height: 24px;
                    @include ColorPrincipal;
                    &:hover {
                        text-decoration: none;
                        @include ColorSecundario;
                    }
                    &:hover .img-down {
                        filter: brightness(0) saturate(100%) invert(28%) sepia(57%) saturate(2374%) hue-rotate(343deg) brightness(96%) contrast(96%);
                    }
                }
            }
        }
    }
    .user-dropdown {
        left: -100px;
        top: 45px;
        justify-content: center;
        width: 200px;
        .list-general {
            padding-left: 0;
        }
    }
    #user-dropdown-mobile {
        left: -80px;
    }
    /* Selector Lang*/
ul.selector-idioma, .selector-idioma ul{
    list-style:none;
  }

  .selector-idioma {
    margin:0 auto;
    position: relative;
    padding-left: 15px;
  }
  .selector-idioma span {

    font-size:16px;
  }

  .selector-idioma > li {
    float:none;
    cursor:pointer;
  }

  .selector-idioma li a {
    text-decoration:none;
    padding:10px 5px;
    display:block;
    text-align: left;
    background-color: #48474745;
    color: white!important;
    font-size:16px;
  }
  .master_icon:hover path{
      fill: #AFCDD7;
  }

  .face_icon:hover path{
      fill: #AFCDD7;
  }
  .linkedin_icon:hover path{
      fill: #AFCDD7;

  }
  .selector-idioma li a:hover {
    background-color:#434343;
    color: #051C2C !important;
  }
  #languages li a:hover{
    color:#051C2C!important;
    border-bottom: none;
  }
  .selector-idioma li ul {
    display:none;
    position:absolute;
  }

  .selector-idioma li:hover > ul {
    display:block;
  }
  .selector-idioma .desktop-desplegate-code-lang {
    margin: unset!important;
    padding: unset!important;
    width: auto!important;
    position: absolute;
    left: 30%;
  }
  .selector-idioma li ul li {
    position:relative;
  }

  .selector-idioma li ul li ul {
    right:-140px;
    top:0px;
  }
  .selector-idioma .desktop-desplegate-code-lang li a:hover {
    background-color: transparent !important;
  }
  .selector-idioma li ul li a:hover {
    background-color: transparent !important;
  }
}
.header-hide{
    top: -120px;
    transition: top 0.5s ease;
}
.dropdownShow {
    top: 100% !important;
    transition: top 0.5s ease !important;
}
@media (max-width: 1401px) {
    header {
        .link-menu {
            margin-left: 15px !important;
        }
        .second-column-group {
            margin-left: 15px !important;
        }
    }
}
@media (max-width: 1200px) {
    .header{
        .selector-idioma {
            padding-right: 15px;
        }
        .container-shoppingbag{
            .content-bag{
                width: 350px;
                .decoration{
                    left: 319px;
                }
            }
        }
        .menu-mobile {
            .links-items {
                margin-top: 39px;
                .button-search {
                    text-align: right;
                    margin-top: 1rem;
                    .btnSearchMenu {
                        padding: 10px 10px;
                        @include LatoPequenoDesktop;
                    }
                }
                .nav-products {
                    .menu-products-mobile {
                        display: none;
                    }
                }
                .nav-services {
                    .menu-services-mobile {
                        display: none;
                    }
                    p {
                        margin-top: 1rem;
                    }
                }
            }
        }
    }
}
@media (max-width: 325px) {
    .header .container-shoppingbag .content-bag{
        width: 292px;
    }
    .header .container-shoppingbag .content-bag .decoration{
        left: 268px;
    }
}
