section.home {
    margin-bottom: 84px;
    .title-section-home {
        font-family: "Lato-Light";
        font-size: 48px;
        line-height: 48px;
        @include ColorPrincipal;
    }
    .first-section {
        margin-top: 47px;
        .title-first-section {
            font-family: "Lato-Light";
            font-size: 48px;
            line-height: 48px;
            @include ColorPrincipal;
            margin-bottom: 0;
        }
        .content-first-section {
            font-family: 'Lato-Light';
            font-size: 30px;
            line-height: 40px;
            @include ColorPrincipal;
        }
    }
    .second-section {
        margin-top: 68px;
        .title-section-products {
            margin-bottom: 40px;
        }
        .content-product {
            min-height: 383px;
            position: relative;
            border-radius: 5px;
            background-size: cover;
            background-repeat: no-repeat;
            .container-title-product {
                padding-left: 30px;
                padding-right: 30px;
            }
            .title-product {
                @include ColorPrincipal;
                font-family: "Lato-Light";
                font-size: 48px;
                line-height: 48px;
                margin-bottom: 0;
            }
            .content-color {
                position: absolute;
                top: 0;
                width: 100%;
                height: 15px;
                transition: 0.8s;
                border-radius: 5px 5px 0px 0px;
                .content-hover {
                    display: none;
                    flex-flow: column;
                    justify-content: center;
                    .title-product-hover {
                        color: #FFFFFF !important;
                    }
                    .description-product-hover {
                        margin-top: 25px;
                        color: #FFFFFF;
                        @include LatoMedianoDesktop;
                        padding-left: 20px;
                        padding-right: 20px;
                    }
                    .btn-home {
                        margin-top: 57px;
                        background: transparent;
                        border: 2px solid #FFFFFF;
                        &:hover {
                            background: #FFFFFF;
                            color: #000000;
                        }
                    }
                }
            }
            &:hover{
                .title-product-no-hover {
                    display: none;
                }
                .content-color {
                    height: 100%;
                    top: 0;
                    border-radius: 5px;
                    transition: 0.5s;
                    .content-hover {
                        display: flex;
                        height: 100%;
                    }
                }
            }
        }
        .content-product-1006 {
            .container-title-product {
                padding-left: 70px;
                padding-right: 70px;
            }
            .content-hover {
                .title-product {
                    padding-left: 70px;
                    padding-right: 70px;
                }
            }
        }
    }
    .four-section {
        .content-aplication {
            min-height: 363px;
            .content-dropdown {
                min-height: 120px;
                background: rgba(255, 255, 255, 0.8);
                .title-aplication {
                    @include ColorPrincipal;
                    font-size: 36px;
                    line-height: 40px;
                    font-family: "Lato-Light";
                    margin-top: 12px;
                    .icon-up {
                        transform: rotate(-180deg);
                    }
                }
                .list-aplications {
                    display: none;
                    .description-aplication {
                        color: #000000;
                        @include LatoMedianoDesktop;
                    }
                    .link-aplication {
                        font-size: 20px;
                        line-height: 21px;
                        font-family: "Lato-Light";
                    }
                    .line-item {
                        border-top: 1px solid #8A8D8E;
                        margin-top: 5px;
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }
    .six-section {
        margin-top: 59px;
        .title-section {
            @include ColorPrincipal;
            font-family: "Lato-Light";
            font-size: 48px;
            line-height: 48px;
        }
    }
}
.title-five-section {
    color: #66BC29;
    font-family: "Lato-Light";
    font-size: 48px;
    line-height: 48px;
    margin-bottom: 22px;
}
.third-section {
    background: #b9bbbb;
    margin-top: 94px;
    margin-bottom: 104px;
    padding-top: 80px;
    padding-bottom: 80px;
    .text-third-section {
        color: #FFFFFF;
        font-family: "Lato-Light";
        font-size: 30px;
        line-height: 40px;
    }
}
.five-section {
    background: #f0f8ea;
    padding-top: 50px;
    padding-bottom: 70px;
    .row-valores-images-2 {
        margin-top: 80px;
    }
    .content-valor {
        max-width: 427px;
        .title-valor  {
            margin-top: 25px;
            margin-bottom: 25px;
            font-family: "Lato-Regular";
            font-size: 30px;
            line-height: 30px;
            color: #66BC29;
        }
        .text-valor {
            font-family: "Lato-Light";
            font-size: 20px;
            line-height: 26px;
            @include ColorPrincipal;
        }
        .link-valor {
            font-family: "Lato-Regular";
            font-size: 20px;
            line-height: 26px;
            color: #66BC29;
            text-decoration: none;
            font-weight: 600;
        }
    }
    .title-section {
        font-size: 52px;
        line-height: initial;
        font-family: "Lato-Bold";
    }
    .text-section {
        @include LatoMedianoMobile;
        @include ColorPrincipal;
    }
    .content-valores {
        margin-top: 40px;
        .valores {
            max-width: 285px;
            width: 100%;
        }

    }
}

@media (max-width: 991px) {
    section.home {
        .title-section-home {
            font-family: "Lato-Light";
            font-size: 36px;
            line-height: 40px;
        }
        .first-section {
            .title-first-section {
                font-size: 42px;
                line-height: 43px;
            }
            .content-first-section {
                font-size: 21px;
                line-height: 21px;
            }
        }
        .second-section {
            .content-product {
                min-height: 270px;
                .btn-home {
                    margin-top: 45px;
                    border: 2px solid #8A8D8E;
                    border-radius: 3px;
                    background: transparent;
                    @include ColorPrincipal;
                    &:hover {
                        background : #8A8D8E;
                        color: #FFFFFF;
                    }
                }
                .content-color {
                    min-height: 120px;
                    position: relative;
                    .title-product {
                        color: #FFFFFF;
                        @include LatoGrandeDesktop;
                    }
                    .content-hover {
                        display: none;
                    }
                }
                .title-product-desktop {
                    display: none;
                }
            }
        }
        .four-section {
            .content-aplication {
                min-height: 257px;
                .content-dropdown {
                    min-height: 257px;
                    .title-aplication {
                        font-size: 33px;
                        line-height: 35px;
                    }
                    .list-aplications {
                        display: flex;
                        .description-aplication {
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
        .six-section {
            .content-news {
                margin-bottom: 20px;
            }
        }
    }
    .third-section {
        padding-top: 50px;
        padding-bottom: 50px;
        .text-third-section {
            font-size: 26px;
            font-family: "Lato-Light";
            line-height: 30px;
        }
    }
    .title-five-section {
        font-size: 30px;
        line-height: 40px;
    }
    .five-section {
        .content-valor {
            margin-top: 40px;
            img {
                max-width: 80px;
                width: 100%;
            }
            .title-valor {
                font-size: 33px;
                line-height: 35px;
                margin-top: 0px;
            }
            .text-valor {
                @include LatoPequenoDesktop;
                line-height: 18px;
            }
            .link-valor {
                @include LatoMedianoMobile;
                font-family: "Lato-Regular";
            }
        }
        .content-valor-1 {
            img {
                max-width: 70px;
            }

        }
        .content-valor-2 {
            img {
                max-width: 100px;
            }
        }
        .content-valor-3 {
            img {
                max-width: 117px;
            }

        }
        .content-valor-4  {
            img {
                max-width: 70px;
            }

        }
        .content-valor-5 {
            img {
                max-width: 112px;
            }

        }
    }
}
