section.cart.steps-2 {
    padding-bottom: 100px;
    .container-user{
        font-size: 24px;
    }
    .container-form{
        .text-link {
            .link-go-back {
                @include ColorPrincipal;
                @include LatoPequenoDesktop;
                text-decoration: underline;
            }
            margin-top: 38px;
            margin-bottom: 45px;
        }

        .accede {
            @include ColorPrincipal;
            @include LatoGrandeMobile;
            margin-bottom: 36px;
        }
        .link-forgot-pwd {
            a {
                @include ColorSecundario;
                @include LatoMedianoMobile;
            }
        }
        .form-control-input-textarea{
            min-height: 57px;
        }
        .content-register {
            margin-top: 40px;
            .title-register {
                @include LatoGrandeMobile;
                @include ColorPrincipal;
            }
        }
        .title-section {
            @include LatoMedianoDesktop;
            @include ColorPrincipal;
            margin-top: 40px;
            margin-bottom: 19px;
        }
        .datos-envio {
            margin-top: 20px;
        }
        .select-step2 {
            font-family: "Lato-Light";
            font-size: 18px;
            line-height: 20px;
            color: #000000;
            height: 44px;
        }
        .form-check-group {
            .privacity {
                @include LatoMedianoMobile;
            }
        }
        .container-forgotten {
            .form-check-group {
                padding-left: 22px;
                .form-check-label {
                    @include LatoMedianoMobile;
                    @include ColorPrincipal;
                }
            }
        }
    }
    .container_bill{
        display: none;
    }
    .policy-input {
        margin-top: 33px;
        max-width: 452px;
        width: 100%;
        .form-check-group {
            padding-left: 20px;
        }
    }
    .hidden-google {
        font-family: "Lato-Light";
        line-height: 17px;
        a {
            color: #000000;
            text-decoration: underline;
            &:hover {
                @include ColorSecundario;
            }
        }
    }
    .form-group {
        &.animated-input {
            label {
                position: absolute;
                font-family: "Lato-Light";
                font-size: 18px;
                line-height: 20px;
                top: 12px;
                left: 16px;
                -webkit-transition: top .2s ease-in-out, font-size .2s ease-in-out;
                transition: top .2s ease-in-out,  font-size .2s ease-in-out;
                &.active {
                    top: -24px;
                    left: 0;
                    font-size: 18px;
                    line-height: 20px;
                }
            }
            input, select, textarea {
                font-family: "Lato-Light";
                font-size: 18px;
                line-height: 20px;
                height: 44px;
            }
        }
    }
}

@media (max-width: 991px) {
    section.cart.steps-2 {
        padding-bottom: 150px;
        .content-resumen {
            margin-top: 50px;
            .block_product {
                max-width: 100%;
            }
        }
        .policy-input {
            max-width: 100%;
        }
    }
}
