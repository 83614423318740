section.error-404{
    height: 100vh;
    padding-top: calc(50vh - 194px);
    header,footer{
        display: none;
    }
    .logo{
        width: 100%;
        height: 100%;
        max-width: 152.33px;
        max-height: 142px;
        margin-bottom: 65px;
    }
    .title{
        margin-bottom: 10px;
        font-family: "Lato-Regular";
        @include media-breakpoint-down(md) {
            font-size: 74px;
            line-height: 87px;
        }
    }
    .subtitle{
        margin-bottom: 20px;
        font-family: "Lato-Light";
        @include media-breakpoint-down(md){
            font-size: 32px;
            line-height: 41px;
        }
    }
    .link{
        font-family: "Lato-Light";
        @include media-breakpoint-down(md){
            font-size: 16px;
            line-height: 24px;
        }
    }
}
