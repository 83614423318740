section.quality-certificates {
    .content-certificate {
        padding-top: 76px;
        padding-bottom: 66px;
        .title-certificate {
            @include LatoExtraGrande;
            @include ColorSecundario;
            margin-bottom: 21px;
        }
        .subtitle-certificate {
            @include LatoGrandeMobile;
            @include ColorPrincipal;
            margin-bottom: 41px;
        }
        .text1-certificate {
            @include LatoMedianoMobile;
            margin-bottom: 64px;
            color: #000000;
            a {
                color: #000000;
                &:hover {
                    @include ColorSecundario;
                    text-decoration: none;
                }
            }
        }
        .no-validate {
            @include LatoMedianoDesktop;
            @include ColorPrincipal;
            line-height: 28px;
            a {
                @include ColorPrincipal;
                &:hover {
                    @include ColorSecundario;
                    text-decoration: none;
                }
            }
        }
        .error-back-certificate {
            @include LatoMedianoMobile;
        }
        .form-certificate {
            input {
                background: #F8F8F8;
                border: 0.5px solid #4F4F4F;
                border-radius: 3px;
                padding: 19px 21px 19px 20px;
                font-size: 18px;
                font-family: "Lato-Light";
                line-height: 20px;
            }
        }
        .num_lote_form {
            .animated-input {
                margin-bottom: 30px;
            }
        }
        .container-img-certificated{
            .img-certificate {
                max-width: 100%;
                width: 100%;
                height: auto;
                object-fit: cover;
                margin-bottom: 20px;
            }
        }
        .login-content {
            margin-bottom: 24px;
            .form-check-group {
                padding-left: 22px;
                .form-check-label {
                    @include LatoMedianoMobile;
                    @include ColorPrincipal;
                }
            }
            .password-content {
                .link-pwd {
                    @include ColorSecundario;
                    @include LatoMedianoMobile;
                }
            }
        }
    }

    .error-certificate {
        @include LatoPequenoDesktop;
        color: red;
        margin-top: 8px;
        a {
            color: red;
            &:hover {
                @include ColorPrincipal;
                text-decoration: none;
            }
        }
    }

    .form-group {
        &.animated-input {
            label {
                position: absolute;
                font-family: "Lato-Light";
                font-size: 18px;
                line-height: 20px;
                top: 12px;
                left: 16px;
                -webkit-transition: top .2s ease-in-out, font-size .2s ease-in-out;
                transition: top .2s ease-in-out,  font-size .2s ease-in-out;
                &.active {
                    top: -24px;
                    left: 0;
                    font-size: 18px;
                    line-height: 20px;
                }
            }
            input, select, textarea {
                font-family: "Lato-Light";
                font-size: 18px;
                line-height: 20px;
                height: 44px;
            }
        }
    }
}

@media (max-width: 768px) {
    section.quality-certificates {
        .content-certificate {
            .img-certificate {
                margin-top: 50px;
            }
        }
    }
}
