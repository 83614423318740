.download_area_background {
    background: #f9f9f9;
    footer {
        margin-top: 0;
    }
}
section.download_area {
    background: url('/images/banner/banner_download_desktop.jpg') no-repeat right top;
    background-size: 54%;
    height: 90vh;
    .download-items {
        padding-top: 69px;
        .title-section {
            @include LatoExtraGrande;
            @include ColorSecundario;
        }
        .subtitle-section {
            @include LatoGrandeMobile;
            @include ColorPrincipal;
            margin-bottom: 88px;
        }
        .title-catalogue {
            @include LatoGrandeMobile;
            @include ColorSecundario;
            font-family: "Lato-Regular";
            .img-red {
                width: 24px;
                height: 24px;
                margin-right: 10px;
            }
        }
        .title-documents {
            margin-top: -35px;
            padding-left: 0px;
            @include LatoGrandeMobile;
            @include ColorSecundario;
            font-family: "Lato-Regular";
        }
        .content-item {
            margin-top: 16px;
            height: 209px;
            .list-content-items{
                position: absolute;
                left: -131px;
                right: 0;
                background: white;
                padding-top: 12px;
                padding-bottom: 12px;
                padding-right: 40px;
                padding-left: 133px;
            }
            .content-catalog {
                padding-left: 10px;
                padding-right: 0px;
                .text1 {
                    font-family: "Lato-Light";
                    font-size: 18px;
                    line-height: 20px;
                    color: black;
                }
                .text2 {
                    float: right;
                    @include LatoMedianoDesktop;
                    &:hover {
                        .img-download {
                            path {
                                stroke: #b32d20;
                            }
                        }
                    }
                    .img-download {
                        margin-left: 20px;
                    }
                }
            }
            .line-item {
                border-top: 0.5px solid #8A8D8E;
            }
        }
    }
}

@media (max-width: 1350px) {
    .download_area_background {
        footer {
            margin-top: 150px;
        }
    }
    section.download_area {
        background-size: 46% 90%;
        .download-items {
            .content-item {
                .list-content-items {
                    width: 750px;
                }
            }
        }
    }
}

@media (max-width: 991px) {
    section.download_area {
        background: none;
        height: auto;
        .column-image {
            padding-left: 0;
            padding-right: 0;
            .img-mobile {
                width: 100%;
            }
        }
    }
}

@media (max-width: 767px) {
    section.download_area {
        .download-items {
            .title-catalogue {
                .img-red {
                    width: 32px;
                }
            }
            .title-documents {
                padding-left: 0px;
            }
            .content-item {
                .list-content-items {
                    width: auto;
                }
                .content-catalog {
                    padding-left: 8px;
                    padding-right: 0px;
                }
            }
        }
    }
}

@media (max-width: 525px) {
    section.download_area {
        .download-items {
            .content-item {
                .content-catalog {
                    .text1 {
                        font-size: 15px;
                    }
                    .text2{
                        .text-link {
                            display: none;
                        }
                        .img-download {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}
