.content-news {
    .link-general-news {
        text-decoration: none;
        &:hover {
            text-decoration: none;
        }
    }
    .card-news {
        background-color: #f3f4f4;
        border: none;
        .new-image {
            height: 340px;
            object-fit: cover;
        }
        .card-content-new {
            min-height: 280px;
            .new-date {
                @include LatoMedianoMobile;
                font-family: "Lato-Regular";
                color: #4F4F4F;
                line-height: 20px;
            }
            .new-title {
                font-family: 'Lato-Light';
                font-size: 24px;
                color: #4F4F4F;
                line-height: 26px;
                min-height: 52px;
            }
            .new-text {
                div, p, span {
                    font-family: "Lato-Light";
                    font-size: 16px;
                    line-height: 20px;
                    color: #8A8D8E;
                    margin-bottom: 0;
                }
                @include LatoMedianoMobile;
                line-height: 20px;
                min-height: 3.6vw;
                margin-bottom: 20px;
                @include ColorPrincipal;
            }
        }

        .btn-new {
            margin-top: 20px;
            background: none;
            border: 1px solid #8A8D8E;
            color: #8A8D8E;
            &:hover {
                background: #8A8D8E;
                color: #FFFFFF;
            }
        }
    }
}

section.news {
    .title-news {
        @include LatoXlDesktop;
        @include ColorSecundario;
        margin-top: 66px;
        margin-bottom: 94px;
    }
    .content-news {
        margin-bottom: 40px;
    }
    .button-see-more {
        margin-top: 62px;
        margin-bottom: 62px;
    }
}

section.detail-news {
    margin-bottom: 35px;
    .content-new {
        @include LatoMedianoMobile;
        .line-item {
            border-top: 0.5px solid #8A8D8E;
            margin-bottom: 70px;
            margin-top: 45px;
        }
    }
    .icons-social-networks {
        max-width: 300px;
        width: 100%;
        .title-social-networks {
            @include LatoMedianoMobile;
            @include ColorPrincipal;
        }
        .icon-share {
            width: 24px;
            height: 24px;
            path {
                fill: #E03828;
            }
            &:hover {
                path {
                    fill: #B32D20;
                }
            }
        }
    }
    .title-recent-new {
        @include LatoGrandeMobile;
        margin-bottom: 35px;
    }
}

@media (max-width: 991px) {
    section.news {
        .title-news {
           font-family: "Lato-Light";
           font-size: 36px;
           line-height: 40px;
           margin-bottom: 73px;
        }
    }
    section.detail-news {
        .content-news {
            margin-top: 20px;
        }
    }
}
