.productDetail{
    .row-products-general {
        margin-top: 33px;
        .datos-grado {
            .title-general {
                @include LatoGrandeMobile;
                @include ColorPrincipal;
            }
            .title-grado {
                font-family: "Lato-Regular";
                font-size: 40px;
                line-height: 45px;
            }
            .select-option {
                margin-top: 40px;
                .content-row {
                    color: #FFFFFF;
                    @include LatoMedianoMobile;
                    min-height: 77px;
                    border-right: 2px solid #FFFFFF;
                    cursor: pointer;
                }
            }
            .descripcion {
                @include LatoMedianoMobile;
                margin-top: 21px;
                margin-bottom: 62px;
                color: #000000;
            }
            .features{
                margin-bottom: 70px;
                position: relative;
                .list-features {
                    position: absolute;
                    left: -131px;
                    right: 0;
                    background: rgba(138, 141, 142, 0.1);
                    padding-top: 20px;
                    padding-bottom: 20px;
                    padding-right: 40px;
                    padding-left: 133px;
                    @include LatoMedianoMobile;
                    p {
                        margin-bottom: 0;
                        @include LatoMedianoMobile;
                        line-height: 18px;
                        color: #000000;
                    }
                    span {
                        @include LatoMedianoMobile;
                        font-family: "Lato-Light";
                        font-size: 16px;
                        line-height: 18px;
                        color: #000000;
                    }
                }
            }
            .aplications {
                margin-top: 17px;
                margin-bottom: 78px;
                position: relative;
                .list-aplications {
                    position: absolute;
                    left: -131px;
                    right: 0;
                    background: rgba(138, 141, 142, 0.1);
                    padding-top: 20px;
                    padding-bottom: 20px;
                    padding-right: 40px;
                    padding-left: 133px;
                    .text-aplication {
                        color: #000000;
                        @include LatoMedianoMobile;
                        line-height: 18px;
                    }
                }
            }
            .lineItem {
                border-top: 1px solid #8A8D8E;
            }
            .technical-specifications {
                .technical-specifications-img {
                    cursor: pointer;
                    height: auto;
                    img {
                        object-fit: contain;
                        width: 100%;
                        height: auto;
                    }
                }
            }
            .btnDownloadTDS {
                display: flex;
                max-width: 230px;
                align-items: center;
            }
        }
        .shop-online {
            .title-shop-online {
                margin-top: 57px;
                margin-bottom: 10px;
                @include LatoGrandeMobile;
                font-family: "Lato-Regular";
            }
            table{ //para firefox
                scrollbar-color: #8A8D8E #d0d1d2;
                scrollbar-width: thin;
            }
            table::-webkit-scrollbar {
                width: 6px;
                height: 6px;
            }
            table::-webkit-scrollbar-thumb {
                background-color: #8A8D8E;
                border-radius: 4px;
            }
            table::-webkit-scrollbar-track {
                background: #d0d1d2;
                border-radius: 4px;
            }
            .table-shop-online {
                width: 100%;
                height: calc(743px - 0px);
                border-collapse: separate;
                border-spacing: 11px;
                &.height-auto {
                    height: auto;
                }
                th {
                    @include ColorSecundario;
                    @include LatoGrandeMobile;
                    border-bottom: 1px solid #E03828;
                    padding: 0;
                    padding-bottom: 13px;
                    font-weight: normal;
                    border-top: none;
                }
                td {
                    @include LatoMedianoMobile;
                    @include ColorPrincipal;
                    border-bottom: 1px solid #8A8D8E;
                    padding: 0px;
                    padding-bottom: 10px;
                    border-top: none;
                }
                .no-border {
                    border-bottom: none !important;
                }
                .row-name {
                    @include LatoPequenoDesktop;
                    font-family: 'Lato-Bold';
                    color: #000000;
                }
                .row-addCart {
                    border-bottom: none;
                    #buton-comprar {
                        background: #d0d1d2;
                        width: 100%;
                        max-width: 150px;
                        &:hover {
                            background: #b2b3b4;
                        }
                    }
                    .btnUnderRequest {
                        background: #d0d1d2;
                        &:hover {
                            background: #b2b3b4;
                        }
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
                .popup {
                    display: none;
                    position: absolute;
                    background: #FFFFFF;
                    max-width: 100%;
                    width: 100%;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
                    border-radius: 5px;
                    z-index: 100;
                    left: 0;
                    right: 0;
                    padding: 10px;
                    padding-top: 24px;
                    top: -50px;
                    .close-popup {
                        top: 4px;
                        right: 0;
                        font-size: 24px;
                        width: 30px;
                        position: absolute;
                        cursor: pointer;
                    }
                    .text-popup {
                        white-space: normal;
                    }
                }
            }
            .line-item-more {
                .icon-more {
                    margin-top: 10px;
                    .imgDown {
                        cursor: pointer;
                    }
                    .imgUp {
                        cursor: pointer;
                        transform: rotate(3.142rad);
                    }
                }
            }
        }
        .product-references {
            .references-content {
                .img-example-product {
                    max-width: 48%;
                    height: auto;
                    margin-top: 50px;
                }
                .img-example-product-1619 {
                    max-width: 80%;
                }
            }
        }
        .shop-under-request {
            margin-top: 50px;
            margin-bottom: 35px;
            .btn-under-request {
                vertical-align: bottom;
            }
            .text-under-request {
                color: #8A8D8E;
                font-family: 'Lato-Light';
                line-height: 16px;
                vertical-align: bottom;
                margin-left: 15px;
            }
        }
    }
    .modalContent {
        background-color: rgba(0,0,0,0.4);
        .modalContainer {
            max-width: 1000px;
            .contentModal {
                border: none;
            }
            .closeModal {
                max-width: fit-content;
                text-align: right;
                padding-right: 1rem;
                padding-top: 1rem;
                cursor: pointer;
            }
            &.widthContent {
                max-width: 800px;
            }
        }
    }
}

@media (max-width: 768px) {
    .productDetail {
        .row-products-general {
            .datos-grado {
                .title-general {
                    display: none;
                }
                .features, .aplications {
                    margin-top: 0;
                    .list-features, .list-aplications {
                        right: -15px;
                    }
                }
            }
            .shop-online {
                .button-group {
                    margin-top: 18px;
                    p {
                        max-width: 284px;
                        a {
                            width: 100%;
                        }
                    }
                    .btn-equivalence {
                        margin-bottom: 5px;
                    }
                }
                .table-shop-online {
                    display: block;
                    overflow-x: auto;
                    height: auto;
                    white-space: nowrap;
                    .padding-general {
                        padding: 0.5em 1.7em;
                    }
                    th, td {
                        padding: 0.5em 0.7em;
                    }
                }
            }
            .shop-under-request {
                margin-top: 35px;
                .text-under-request-mobile {
                    margin-bottom: 15px;
                    margin-left: 0;
                    @include LatoMedianoMobile;
                }
            }
            .img-references {
                overflow-y: auto;
                .img-mobile {
                    max-width: 270%;
                }
            }
        }
    }
}

@media (max-width: 390px) {
    .productDetail {
        .shop-online {
            table {
                display: block;
                overflow-x: auto;
                white-space: nowrap;
                .padding-general {
                    padding: 0.5em 1.2em;
                }
                th, td {
                    padding: 0.5em 0.4em;
                }
            }
        }
    }
}

