.window-under-request {
    position: fixed;
    display: none;
    bottom: 0;
    transition: bottom 0.5s ease;
    z-index: 99;
    right: 130px;
    max-width: 508px;
    width: 100%;
    background: #FFFFFF;
    border-radius: 8px 8px 0px 0px;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.25);
    &.hide{
        transition: bottom 0.5s ease;
    }
    .under-request-header {
        border-radius: 8px 8px 0px 0px;
        padding: 20px;
        .content-under-request {
            .title-under-request {
                color: #FFFFFF;
                @include LatoGrandeMobile;
                line-height: 34px;
            }
        }
    }
    .under-request-form-content {
        padding: 25px 60px 25px 25px;
        .under-request-form {
            .form-group {
                margin-top: 17px;
                margin-bottom: 35px !important;
                label {
                    @include ColorPrincipal;
                    font-family: "Lato-Light";
                    font-size: 18px;
                    line-height: 20px;
                    top: 10px;
                    &.active {
                        top: -27px;
                        left: 0;
                    }
                }
                input {
                    @include ColorPrincipal;
                    font-family: "Lato-Light";
                    font-size: 18px;
                    line-height: 20px;
                    height: 44px;
                }
                textarea {
                    @include ColorPrincipal;
                    font-family: "Lato-Light";
                    font-size: 18px;
                    line-height: 20px;
                    border: 0.5px solid #4F4F4F;
                    border-radius: 3px;
                    background: rgba(248, 248, 248, 0.8);
                    min-height: 94px;
                    resize: none;
                }
                &.last {
                    margin-bottom: 20px !important;
                }
            }
            .form-check-group {
                padding-left: 25px;
                &.last {
                    margin-bottom: 22px;
                }
            }
            .alert {
                margin-bottom: 2rem;
            }
            .example-content {
                margin-bottom: 20px;
                .example-block-content {
                    max-width: 150px;
                    .example-block {
                        @include ColorPrincipal;
                        @include LatoPequenoDesktop;
                        background-color: rgba(248, 248, 248, 0.8);
                        border: 0.5px solid #4F4F4F;
                        border-radius: 3px;
                        padding: 10px;
                        .img-down {
                            path {
                                stroke: #000000;
                            }
                            &:hover {
                                stroke: #000000;
                            }
                        }
                    }
                    .example-type {
                        .text-type {
                            @include ColorPrincipal;
                            @include LatoPequenoDesktop;
                        }
                    }
                    .text-bold {
                        color: #000000;
                        font-weight: bold;
                    }
                    .text-info-request {
                        margin-top: 2px;
                        @include ColorPrincipal;
                        @include LatoPequenoMobile;
                    }
                }

            }
        }
    }
}
