.form-group {
    &.animated-input {
        position: relative;
        margin-top: 25px;
        margin-bottom: 35px !important;
        label {
            position: absolute;
            top: 6px;
            left: 16px;
            -webkit-transition: top .2s ease-in-out, font-size .2s ease-in-out;
            transition: top .2s ease-in-out,  font-size .2s ease-in-out;
            background-color: transparent;
            &.active {
                top: -12px;
                font-family: "Lato-Light";
                font-size: 18px;
                line-height: 20px;
            }
        }
        input, select {
            background-color: rgba(248, 248, 248, 0.8);
            border: 0.5px solid #4F4F4F;
            border-radius: 3px
        }
        select {
            appearance: none;
            background:  url('/img/icons/down.svg') no-repeat scroll 359px center transparent;
        }
        .icon-error {
            width: 18px;
            height: 18px;
            path {
                stroke: red;
            }
        }
        .is-invalid {
            background-image: none;
        }
    }
}

