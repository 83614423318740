.container-navegacion{
    padding-top: 18px;
    .fas.fa-home{
        font-family: 'PhpDebugbarFontAwesome';
        font-style: normal;
        color:black;
        font-size: 17px;
        &:hover{
            color:#00bde0
        }
    }
    a{
        @include LatoPequenoDesktop;
        @include ColorPrincipal;
        &:hover{
            @include ColorSecundario;
            text-decoration: none;
        }
    }
    span {
        @include LatoPequenoDesktop;
        @include ColorPrincipal;
        &.separator{
            margin: 0px 10px;
            font-size: initial;
        }
        .active {
            font-family: 'Lato-Bold';
        }
    }
}
