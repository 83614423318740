#modalLoginUser {
    background: rgba(0, 0, 0, 0.4);
    .modal-dialog{
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        margin: auto;
        .modal-content {
            overflow-y: auto;
            max-height: 100%;
            border: none;
            .login-form {
                max-width: 369px;
                margin: auto;
                .title-login {
                    @include LatoMedianoDesktop;
                    color: #05232D;
                    margin-bottom: 14px;
                }
                .subtitle-login {
                    @include LatoMedianoMobile;
                    color: #000000;
                    margin-bottom: 20px;
                }
                .link-forgot-pwd {
                    a {
                        color: #E03828;
                        font-family: "Lato-Light";
                        font-size: 16px;
                        line-height: 24px;
                        &:hover {
                            text-decoration: underline;
                        }
                    }

                }
            }
            .register-content {
                max-width: 369px;
                margin-left: auto;
                margin-right: auto;
                margin-top: 53px;
                margin-bottom: 25px;
                .title-register-content {
                    @include LatoMedianoDesktop;
                    color: #05232D;
                    margin-bottom: 12px;
                }
            }
        }
        .img-close-login {
            position: absolute;
            right: 1rem;
            cursor: pointer;
        }
    }
}
section.register, #modalLoginUser, section.area-cliente, section.reset-password {
    .form-group {
        &.animated-input {
            label {
                position: absolute;
                font-family: "Lato-Light";
                font-size: 18px;
                line-height: 20px;
                top: 12px;
                @include ColorPrincipal;
                left: 16px;
                -webkit-transition: top .2s ease-in-out, font-size .2s ease-in-out;
                transition: top .2s ease-in-out,  font-size .2s ease-in-out;
                &.active {
                    top: -24px;
                    left: 0;
                    font-family: "Lato-Light";
                    font-size: 18px;
                    line-height: 20px;
                    @include ColorPrincipal;
                }
            }
            input, select, textarea {
                font-family: "Lato-Light";
                font-size: 18px;
                line-height: 20px;
                @include ColorPrincipal;
                height: 44px;
            }
        }
    }
}
section.register, section.area-cliente {
    .title-std-register {
        margin-top: 50px;
        margin-bottom: 8px;
        @include ColorSecundario;
        @include LatoExtraGrande;
    }
    .subtitle-std-register {
        @include ColorPrincipal;
        @include LatoGrandeMobile;
        line-height: 34px !important;
        margin-bottom: 20px;
    }
    .text-std-register {
        color: #000000;
        @include LatoMedianoMobile;
    }
    .text-info-std {
        margin-bottom: 40px;
    }
    .text-success {
        @include LatoMedianoMobile;
        margin-top: 20px;
        color: #66BC29;
    }
}

section.register {
    .form-register {
        margin-top: 64px;
        max-width: 425px;
        width: 100%;
    }
}

section.area-cliente {
    .subtitle-section-std {
        margin-top: 68px;
        margin-bottom: 30px;
        color: #000000;
        @include LatoGrandeMobile;
        line-height: 34px !important;
    }
    .btn-form {
        width: 100%;
        margin-top: 15px;
    }
    .container-password {
        max-width: 425px;
        .text-change-password {
            @include LatoMedianoMobile;
            @include ColorSecundario;
            cursor: pointer;
        }
    }
}

section.reset-password {
    .container-password {
        .list-errors {
            li {
                @include LatoPequenoDesktop;
                font-family: "Lato-Regular";
            }
        }
    }
}

@media (max-width: 991px) {
    section.area-cliente {
        margin-bottom: 120px;
    }
}
