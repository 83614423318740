.transfer{
    font-size: 18px;
    .row-logo {
        margin-top: 20px;
        margin-bottom: 37px;
    }
    .step-content{
        margin-bottom: 20px;
    }
    .row-title {
        margin-bottom: 57px;
    }
    .row-first-text {
        .text-content{
            max-width: 464px;
        }
    }
    .number{
        border: 1px solid black;
        padding: 4px;
        border-radius: 50%;
    }
    .row-step1{
        margin-top: 20px;
        .ue-content, .not-ue-content, .iban-content {
            margin-top: 33px;
        }
    }
    .row-step2{
        margin-top: 20px;
        .title-email{
            max-width: 500px;
        }
        .text-email {
            font-size: 18px;
        }
        .column-detail-compra {
            margin-top: 11px;
            .title-compra {
                font-size: 22px;
            }
            .text-compra {
                color: #a4a097;
                font-size: 16px;
            }
        }
    }
    .row-detail {
        margin-top: 21px;
        .title-detail{
            font-size: 22px;
        }
        .text-detail{
            color: #a4a097;
            font-size: 16px;
        }
    }
    .row-contact {
        margin-top: 21px;
    }
    .row-product{
        margin-top: 21px;
        .product-content{
            margin-top: 30px;
            .img-product{
                width: 100%;
                height: 200px;
            }
        }
        .total-content{
            margin-top: 21px;
            .total {
                font-size: 24px;
                .total-price{
                    color: #c70000;
                }
            }
        }
    }
}
